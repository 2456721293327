import type { FC } from "react";
import { useForm } from "react-hook-form";

import { Button, CmsError, Heading, Input, PopupWindow } from "src/components";
import { isCmsErrorResponseWithMessage, removeAuthToken, useForgotPasswordMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logger } from "./logger";

type FormFields = {
    email: string;
};

export const ForgotPasswordForm: FC = () => {
    const { t } = useTranslation();

    const [forgotPassword, { isLoading, isError, error }] = useForgotPasswordMutation();

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<FormFields>();

    const onSubmit = async (data: FormFields) => {
        try {
            removeAuthToken();
            await forgotPassword(data).unwrap();
            await PopupWindow.fire({
                title: t("forgotPassword.success.popUp.title"),
                text: t("forgotPassword.success.popUp.message"),
                icon: "success",
                confirmButtonText: t("forgotPassword.success.popUp.confirm"),
            });
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    return (
        <div className="w-full space-y-6">
            <Heading level={2} className="mb-8">
                {t("forgotPassword.card.title")}
            </Heading>
            {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
            <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
                <div className="space-y-6">
                    <Input
                        type="text"
                        label={t("forgotPassword.email.placeholder")}
                        {...register("email", { required: true })}
                        error={errors.email?.message}
                    />
                </div>
                <div>
                    <Button type="submit" isLoading={isLoading} disabled={!isValid} fullWidth>
                        {t("forgotPassword.button.send")}
                    </Button>
                </div>
            </form>
        </div>
    );
};
