import type { FC } from "react";

import { NoContent, Table, TableBody, TableHead, TableHeader, TableRow } from "src/components";
import { useTranslation } from "src/translations";
import type { OrderListResponse } from "src/types";
import { OrderItem } from "./OrderItem";

type OrdersTableProps = Readonly<{
    orders: OrderListResponse;
}>;

export const OrdersTable: FC<OrdersTableProps> = ({ orders }) => {
    const { t } = useTranslation();

    if (!orders?.data?.length) {
        return <NoContent transKey="orders.list.noOrders" />;
    }

    // TODO: tablet/breakpoint view

    return (
        <div className="max-w-full overflow-hidden">
            <Table className="bg-white">
                <TableHeader className="border-b border-primary">
                    <TableRow className="text-sm text-secondary [&>th]:px-3 [&>th]:pb-3">
                        <TableHead className="pl-0">{t("orders.list.order")}</TableHead>
                        <TableHead>{t("orders.list.project")}</TableHead>
                        <TableHead className="text-center">{t("orders.list.date")}</TableHead>
                        <TableHead className="text-center">{t("orders.list.units")}</TableHead>
                        <TableHead className="text-right">{t("orders.list.cost")}</TableHead>
                        <TableHead>{t("orders.list.state")}</TableHead>
                        <TableHead>{t("orders.list.paymentType")}</TableHead>
                        <TableHead className="pr-0" />
                    </TableRow>
                </TableHeader>
                <TableBody>{orders?.data.map((order) => <OrderItem key={order?.id} order={order} />)}</TableBody>
            </Table>
        </div>
    );
};
