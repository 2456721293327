import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import i18n from "i18next";
import qs from "qs";

import {
    APP_CONFIG_TAG,
    ARTICLE_TAG,
    BANNER_TAG,
    ENTITY_WITH_TRANSLATIONS_TAG,
    FAQ_TAG,
    FUNDED_REQUEST_TAG,
    LOGGED_USER_TAG,
    MESSAGE_TAG,
    ORDER_TAG,
    PROJECT_TAG,
    TRANSLATED_SLUG_TAG,
    USER_PORTFOLIO_TAG,
    VOTING_TAG,
} from "./tags";
import type { AppBaseQueryFn } from "./types";
import { getAuthToken } from "./utils";

const shouldOmitAccessToken = (endpointName: string) => !!endpointName.match(/public[0-9A-Z].*/);

/**
 * Root API which provides shared functionality for all requests (you may add additional functionality, like auth token). This shared functionality consists of:
 * - Base url definition.
 *
 * Add domain specific endpoints by {@link rootApi.injectEndpoints} and tags by {@link rootApi.enhanceEndpoints}.
 * @see https://redux-toolkit.js.org/rtk-query/usage/code-splitting
 */
export const rootApi = createApi({
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers, { endpoint }) => {
            const accessToken = getAuthToken();
            if (accessToken && !shouldOmitAccessToken(endpoint)) {
                headers.set("authorization", `Bearer ${accessToken}`);
            }

            return headers;
        },
        paramsSerializer: (params) => {
            const currentParams = { ...params };
            const locale = currentParams.locale ?? i18n.language;
            return qs.stringify({ ...currentParams, locale });
        },
    }) as AppBaseQueryFn,
    refetchOnFocus: true,
    endpoints: () => ({}),
    tagTypes: [
        LOGGED_USER_TAG,
        PROJECT_TAG,
        FAQ_TAG,
        APP_CONFIG_TAG,
        ORDER_TAG,
        ARTICLE_TAG,
        USER_PORTFOLIO_TAG,
        VOTING_TAG,
        FUNDED_REQUEST_TAG,
        MESSAGE_TAG,
        BANNER_TAG,
        ENTITY_WITH_TRANSLATIONS_TAG,
        TRANSLATED_SLUG_TAG,
    ],
});
