import type { FC } from "react";

import { CmsError, PageLayout } from "src/components";
import { useArticlesQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { ArticleRequestTypeEnum, BannerPageEnum, UserVerificationTypeEnum } from "src/types";
import { isNullish } from "src/utils";
import { useUserVerification } from "src/verification";
import { ArticleCard } from "../components";

export const InvestorClubPage: FC = () => {
    const { t } = useTranslation();

    const { isVerificationLoading, isVerificationDone } = useUserVerification();

    const isInvestor = isVerificationDone(UserVerificationTypeEnum.INVESTOR);

    const { data, isLoading, isError, error } = useArticlesQuery(
        { filters: { type: ArticleRequestTypeEnum.InvestorClub } },
        { skip: !isInvestor },
    );

    const articles = data?.data;

    return (
        <PageLayout
            title={t("articles.investorClub.title")}
            loading={isLoading || isVerificationLoading}
            banner={BannerPageEnum.InvestorClub}
        >
            {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
            {isNullish(articles) || articles?.length === 0 ? (
                <p>{isInvestor ? t("articles.noData") : t("articles.investorClub.notAvailable")}</p>
            ) : (
                articles?.map((article) => <ArticleCard key={article.id} {...article} />)
            )}
        </PageLayout>
    );
};
