import type { FC } from "react";
import { useEffect, useState } from "react";

import { Accordion, AccordionItem, Heading, RichText } from "src/components";
import { CloseIcon } from "src/icons";
import { useFaqsQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { isNotNullish } from "src/utils";

export const Faq: FC = () => {
    const { t } = useTranslation();
    const { data: faqs, isLoading } = useFaqsQuery({});
    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    useEffect(() => {
        if (!isLoading && faqs?.data?.[0]?.id) {
            setExpandedItems([faqs.data[0].id.toString()]);
        }
    }, [faqs, isLoading]);

    const handleExpandAll = () => faqs?.data && setExpandedItems(faqs?.data?.map(({ id }) => id?.toString()).filter(isNotNullish));
    const handleCollapseAll = () => setExpandedItems([]);

    return (
        <div className="mb-20 w-full xl:mb-2">
            <div className="mb-6 flex flex-row justify-between">
                <Heading level={2} className="text-2xl font-medium text-brand xl:text-3xl">
                    {t("projects.detail.description.faq.title")}
                </Heading>
                <div className="flex flex-row gap-2 self-center">
                    <button
                        type="button"
                        className="text-link underline hover:text-link-hover hover:no-underline"
                        onClick={handleExpandAll}
                    >
                        {t("projects.detail.description.faq.expandAll")}
                    </button>
                    <span className="text-sm">/</span>
                    <button
                        type="button"
                        className="text-link underline hover:text-link-hover hover:no-underline"
                        onClick={handleCollapseAll}
                    >
                        {t("projects.detail.description.faq.collapseAll")}
                    </button>
                </div>
            </div>
            <Accordion value={expandedItems} onValueChange={setExpandedItems}>
                {faqs?.data?.map(({ id, title, content }) => (
                    <AccordionItem
                        className="my-2 overflow-hidden rounded border border-primary bg-white p-6"
                        title={
                            <Heading level={3} className="text-left text-lg font-medium">
                                {title}
                            </Heading>
                        }
                        content={content && <RichText content={content} />}
                        triggerClassName="[&>div]:data-[state=closed]:rotate-45"
                        triggerIcon={
                            <div className="transition-transform duration-300 data-[state=closed]:rotate-45">
                                <CloseIcon className="size-8" />
                            </div>
                        }
                        key={id}
                        value={id?.toString() || ""}
                    />
                ))}
            </Accordion>
        </div>
    );
};
