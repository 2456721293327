import { useEffect } from "react";

import { useParseSlugQuery } from "../serverApi";

export const useDocumentIdFromSlug = (
    slug: string,
    apiName: string,
    onTranslatedSlugFound: (slug: string) => void,
    onError: (e: unknown) => void,
) => {
    const { data, isError, error } = useParseSlugQuery({ slug, apiName });

    useEffect(() => {
        if (data?.slug) {
            const composedSlug = `${data.slug}-${data.documentId}`;
            if (slug !== composedSlug) {
                onTranslatedSlugFound(composedSlug);
            }
        }
    }, [slug, data?.slug, onTranslatedSlugFound, data?.documentId]);

    useEffect(() => {
        if (isError) {
            onError(error);
        }
    }, [error, isError, onError]);

    return data?.documentId;
};
