import type { FC } from "react";

import { isCmsErrorResponseWithMessage } from "src/serverApi";
import { useTranslation } from "src/translations";
import { cn } from "src/utils";

type CmsErrorProps = Readonly<{
    error: unknown;
    fallbackMessage: string;
    className?: string;
}>;

export const CmsError: FC<CmsErrorProps> = ({ error, fallbackMessage, className }) => {
    const { t } = useTranslation();

    let errorMessage = fallbackMessage;

    if (isCmsErrorResponseWithMessage(error)) {
        const translationKey = `cmsError.${error.data.error.message
            .replace(/[^A-Za-z0-9\s]/g, "") // remove special characters
            .replace(/\s+/g, "_")}`; // replace spaces with underscores
        errorMessage = t(translationKey, error.data.error.message);
    }

    return <div className={cn("bg-brand-error/20 p-2.5 text-center text-error", className)}>{errorMessage}</div>;
};
