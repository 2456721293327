import type { TFunction } from "i18next";
import type { FC, ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

import { IconWithPopover } from "src/components";
import { InfoIcon } from "src/icons";
import { useTranslation } from "src/translations";
import { isNumber } from "src/utils";

type StatProps = Readonly<{
    value: number | string | undefined;
    unit: number | string | undefined;
    label: string | undefined;
    infoBoxContent?: ReactNode;
    loading: boolean;
    fractionDigits?: number;
}>;

export const Stat: FC<StatProps> = ({ value, unit, label, infoBoxContent, loading, fractionDigits = 0 }) => {
    const { t } = useTranslation();
    return (
        <>
            <figcaption className="flex items-center gap-1 border-0 pb-1 pr-2 pt-2 md:py-3 md:[&:not(:nth-last-child(2))]:border-b">
                {loading ? <Skeleton width={getRandomNumberBetween(110, 130)} /> : <div className="text-sm text-secondary">{label}</div>}
            </figcaption>
            <div className="flex flex-wrap items-center gap-2 pb-2 text-primary md:py-3 [&:not(:last-child)]:border-b md:[&:not(:nth-last-child)]:border-b">
                {loading ? (
                    <Skeleton width={getRandomNumberBetween(60, 130)} height={30} />
                ) : (
                    <>
                        <div className="whitespace-nowrap text-2xl font-medium text-brand-primary md:text-3xl">
                            {formatStat(value, fractionDigits, t)}&nbsp;{unit}
                        </div>
                        {infoBoxContent && (
                            <IconWithPopover icon={<InfoIcon className="size-4 fill-secondary" />}>{infoBoxContent}</IconWithPopover>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

const getRandomNumberBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
const formatStat = (value: number | string | undefined, fractionDigits: number, t: TFunction) =>
    isNumber(value) ? t("format.amount", { value, maximumFractionDigits: fractionDigits }) : value;
