import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Badge, type BadgeIntent, DownloadLink, Link } from "src/components";
import { DownloadIcon } from "src/icons";
import { Routes } from "src/routes";
import type { Project, Voting } from "src/types";
import { VotingStateEnum } from "src/types";
import { cn, getUploadFileName } from "src/utils";
import { VotingCardInfoDate } from "./VotingCardInfoDate";
import { VotingCardInfoWeight } from "./VotingCardInfoWeight";

type FlagMap = Record<VotingStateEnum, { titleKey: string; intent: BadgeIntent }>;

type VotingCardInfoProps = {
    votingItem: Voting;
    className?: string;
};

export const VotingCardInfo: FC<VotingCardInfoProps> = ({ votingItem, className }) => {
    const { t } = useTranslation();

    const project = votingItem?.project as Project;

    const image = project?.images?.length !== 0 ? project?.images?.[0] : null;
    const documentUrl = votingItem.document?.url ? votingItem.document.url : null;

    return (
        <div className={cn("max-w-[21.5rem] space-y-4", { "w-full": !!project }, className)}>
            <div className="flex space-x-4">
                {image && (
                    <div className="aspect-square max-h-20 overflow-hidden">
                        <img className="size-full rounded-none object-cover" src={image.url} alt={project?.projectInfo?.title} />
                    </div>
                )}
                <div className="space-y-3">
                    {project && (
                        <Link
                            href={Routes.projectDetail.fillPathParams({ projectSlug: project?.projectInfo?.slug! })}
                            className="max-w-[13.125rem] truncate text-base hover:text-inherit hover:no-underline"
                        >
                            {project?.projectInfo?.title}
                        </Link>
                    )}
                    <div className="flex items-center justify-between space-x-4">
                        {votingItem.state && (
                            <Badge intent={votingFlagMap[votingItem.state].intent}>{t(votingFlagMap[votingItem.state].titleKey)}</Badge>
                        )}
                        <VotingCardInfoDate votingItem={votingItem} />
                    </div>
                </div>
            </div>
            {documentUrl && (
                <DownloadLink className="flex min-w-[150px] items-center" href={documentUrl}>
                    <DownloadIcon className="mr-3 size-6 shrink-0" />
                    <span>{getUploadFileName(votingItem.document)}</span>
                </DownloadLink>
            )}
            <VotingCardInfoWeight votingItem={votingItem} />
        </div>
    );
};

const votingFlagMap: FlagMap = {
    [VotingStateEnum.Current]: { titleKey: "voting.card.info.flag.current", intent: "subtleSuccess" },
    [VotingStateEnum.Future]: { titleKey: "voting.card.info.flag.future", intent: "subtleWarning" },
    [VotingStateEnum.Past]: { titleKey: "voting.card.info.flag.past", intent: "subtleDanger" },
};
