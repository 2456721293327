import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useEhubDetectSearch = () => {
    const { search } = useLocation();
    useEffect(() => {
        if (search.includes("ehub")) {
            localStorage.setItem("ehub", "true");
        }
    }, [search]);
};
