import type { FC } from "react";

import { NoContent, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components";
import { useTranslation } from "src/translations";
import { DashboardProjectsDesktopItem } from "./DashboardProjectsDesktopItem";
import { DashboardProjectsMobileItem } from "./DashboardProjectsMobileItem";
import type { DashboardProjectsProps } from "./types";

export const DashboardProjects: FC<DashboardProjectsProps> = ({ projects, currency }) => {
    const { t } = useTranslation();

    return (
        <>
            <Table className="hidden font-inter lg:block">
                <TableHeader className="border-b border-subtle [&>th]:pb-3">
                    <TableRow className="text-base">
                        <TableHead className="px-1 text-left">{t("dashboard.projects.name")}</TableHead>
                        <TableHead className="px-1 text-center">{t("dashboard.projects.ownership")}</TableHead>
                        <TableHead className="px-1 text-center">{t("dashboard.projects.units")}</TableHead>
                        <TableHead className="px-1 text-center">{t("dashboard.projects.value")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {projects.map((project) => (
                        <DashboardProjectsDesktopItem key={project.projectInfo?.slug} project={project} currency={currency} />
                    ))}
                    {!projects.length && (
                        <TableRow>
                            <TableCell colSpan={4}>
                                <NoContent transKey="dashboard.projects.empty" />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <div className="flex flex-col gap-4 lg:hidden">
                {projects.map((project) => (
                    <DashboardProjectsMobileItem key={project.projectInfo?.title} project={project} currency={currency} />
                ))}
                {!projects.length && <NoContent transKey="dashboard.projects.empty" />}
            </div>
        </>
    );
};
