import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { Trans, useTranslation } from "src/translations";
import type { ProjectCalculationComponent } from "src/types";
import { calculateAnnualRentalIncome, calculateRemainderAfterXYears, calculateTotalIncome } from "./utils";

type ReturnOfInvestmentResultsProps = Readonly<
    Pick<ProjectCalculationComponent, "annualRentalIncome" | "incrementalPropertyValue" | "period"> & {
        investmentAmount: number;
        currency: string;
        loading: boolean;
    }
>;

export const ReturnOfInvestmentResults: FC<ReturnOfInvestmentResultsProps> = ({
    investmentAmount,
    annualRentalIncome = 1,
    incrementalPropertyValue = 1,
    period = 1,
    currency,
    loading,
}) => {
    const { t } = useTranslation();

    const annualRentalIncomeValue = calculateAnnualRentalIncome(investmentAmount, annualRentalIncome);
    const remainderAfterXYears = calculateRemainderAfterXYears(investmentAmount, incrementalPropertyValue, period);
    const totalIncome = calculateTotalIncome(annualRentalIncomeValue, remainderAfterXYears, period);

    return (
        <div className="flex flex-col items-start justify-start gap-2 self-stretch rounded bg-neutral-100 p-4">
            <div className="flex flex-col items-start justify-start gap-1 self-stretch">
                <div className="items-start justify-start gap-1 self-stretch md:inline-flex">
                    <div className="shrink grow basis-0 text-base font-normal text-black">
                        {t("projects.detail.sidebar.calculator.roi.annualRentalIncomeAmount.label")}
                    </div>
                    <div className="text-base font-bold text-black">
                        {loading ? (
                            <Skeleton width={100} />
                        ) : (
                            t("format.currency", { value: annualRentalIncomeValue, currency, maximumFractionDigits: 0 })
                        )}
                    </div>
                </div>
                <div className="items-start justify-between self-stretch md:inline-flex">
                    <div className="shrink grow basis-0 text-base font-normal text-black">
                        {t("projects.detail.sidebar.calculator.roi.remainderAfterXYears.label", { years: period })}
                    </div>
                    <div className="text-base font-bold text-black">
                        {loading ? (
                            <Skeleton width={100} />
                        ) : (
                            t("format.currency", { value: remainderAfterXYears, currency, maximumFractionDigits: 0 })
                        )}
                    </div>
                </div>
            </div>
            <div className="items-center justify-between self-stretch border-b border-neutral-100 md:inline-flex">
                <div className="shrink grow basis-0 whitespace-pre text-base font-medium text-brand">
                    <Trans i18nKey="projects.detail.sidebar.calculator.roi.totalNetIncome.label" />
                </div>
                <div className="text-[26px] font-medium leading-relaxed text-brand md:text-right">
                    {loading ? <Skeleton width={100} /> : t("format.currency", { value: totalIncome, currency, maximumFractionDigits: 0 })}
                </div>
            </div>
        </div>
    );
};
