import type { FC } from "react";

import { Link } from "src/components";
import { Routes } from "src/routes";
import { useTranslation } from "src/translations";
import { UserVerificationTypeEnum } from "src/types";
import { useUserVerification } from "src/verification";
import { InvestButton, type InvestButtonProps } from "../../../../components";

export const InvestOrSignInButton: FC<InvestButtonProps> = (investButtonProps) => {
    const { t } = useTranslation();
    const { isVerificationDone } = useUserVerification();

    if (isVerificationDone(UserVerificationTypeEnum.SIGNUP)) return <InvestButton {...investButtonProps} />;

    return (
        <div className="flex w-full flex-col items-center gap-4">
            <p className="text-base text-secondary">{t("projects.detail.sidebar.calculator.investOrSignIn.needToSignUp")}</p>
            <InvestButton {...investButtonProps} />
            <p className="text-base text-secondary">
                {t("projects.detail.sidebar.calculator.investOrSignIn.haveAnAccount")}{" "}
                <Link href={Routes.signIn.path}>{t("projects.detail.sidebar.calculator.investOrSignIn.signIn")}</Link>
            </p>
        </div>
    );
};
