import { type FC, useMemo } from "react";

import { Link } from "src/components";
import { ArrowRightIcon } from "src/icons";
import { Routes } from "src/routes";
import { useTranslation } from "src/translations";
import { convertPriceInCentsToPrice } from "src/utils";
import type { DashboardProjectsItemProps } from "./types";

export const DashboardProjectsMobileItem: FC<DashboardProjectsItemProps> = ({ project, currency }) => {
    const { t } = useTranslation();
    const { projectInfo, images, tokenCount, ownershipPercentage, currentValue } = project;
    const thumbnailUrl = images?.[0]?.url;

    const stats = useMemo(
        () => [
            { label: t("dashboard.projects.ownership"), value: t("format.percent", { value: ownershipPercentage }) },
            { label: t("dashboard.projects.units"), value: t("format.amount", { value: tokenCount }) },
            {
                label: t("dashboard.projects.value"),
                value: t("format.currency", { value: convertPriceInCentsToPrice(currentValue), currency }),
            },
        ],
        [currency, ownershipPercentage, t, tokenCount, currentValue],
    );

    return (
        <div className="flex min-w-0 gap-2 font-inter">
            {thumbnailUrl && <img className="size-24 rounded sm:size-32" src={thumbnailUrl} alt={projectInfo?.title} />}
            <div className="flex flex-1 flex-col justify-between">
                <Link
                    href={Routes.projectDetail.fillPathParams({ projectSlug: projectInfo?.slug! })}
                    className="flex items-center justify-between gap-4 text-lg font-semibold text-brand hover:text-brand hover:no-underline focus:text-brand"
                >
                    <p className="truncate">{projectInfo?.title}</p>
                    <ArrowRightIcon className="size-4 shrink-0 fill-brand-primary" />
                </Link>
                <div className="flex flex-col">
                    {stats.map((stat) => (
                        <div key={stat.label} className="flex grow justify-between max-sm:basis-full">
                            <span className="font-medium">{stat.label}:</span>
                            <span>{stat.value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
