import type { FC } from "react";

import { getCurrencyConversionRate } from "src/config";
import { getUserCurrency, useProjectDetailStatsQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { type ProjectInfo, ProjectInfoStateEnum } from "src/types";
import { convertPriceInCentsToPrice, convertPriceToTargetCurrency, daysFromToday, useAppSelector } from "src/utils";
import { Stat } from "./Stat";

type ProjectDetailStatsProps = Readonly<{
    loading: boolean;
    projectState: ProjectInfoStateEnum;
    projectInfo?: ProjectInfo;
}>;

export const ProjectDetailStats: FC<ProjectDetailStatsProps> = ({ loading, projectState, projectInfo }) => {
    const { t } = useTranslation();

    const userCurrency = useAppSelector(getUserCurrency);
    const conversionRate = useAppSelector(getCurrencyConversionRate);
    const project = projectInfo?.project;

    const { data: projectStats, isLoading: isLoadingProjectStats } = useProjectDetailStatsQuery(project?.documentId!, {
        skip: !project?.documentId,
    });

    return (
        <div className="grid md:grid-cols-2">
            {project?.calculation?.paReturn && (
                <Stat
                    key="expectedReturn"
                    value={project.calculation.paReturn}
                    label={t("projects.detail.stats.expectedReturn.label")}
                    infoBoxContent={t("projects.detail.stats.expectedReturn.text")}
                    unit="%"
                    loading={loading}
                    fractionDigits={2}
                />
            )}
            {project?.calculation?.period && (
                <Stat
                    key="period"
                    value={project.calculation.period}
                    label={t("projects.detail.stats.period.label")}
                    infoBoxContent={t("projects.detail.stats.period.text")}
                    unit={t("format.unit.year", { count: project.calculation.period })}
                    loading={loading}
                />
            )}
            {projectState === ProjectInfoStateEnum.Prepared && project?.expectedOn && (
                <Stat
                    key="expectedDate"
                    value={daysFromToday(project.expectedOn)}
                    label={t("projects.detail.stats.expectedDate.label")}
                    unit={t("format.unit.day", { count: daysFromToday(project.expectedOn) })}
                    loading={loading}
                />
            )}
            {projectState === ProjectInfoStateEnum.Open && project?.closedOn && (
                <Stat
                    key="closedDate"
                    value={daysFromToday(project.closedOn)}
                    label={t("projects.detail.stats.closedDate.label")}
                    unit={t("format.unit.day", { count: daysFromToday(project.closedOn) })}
                    loading={loading}
                />
            )}
            {projectStats?.investorsCount !== 0 && (
                <Stat
                    key="investorCount"
                    value={projectStats?.investorsCount}
                    label={t("projects.detail.stats.investorCount.label")}
                    unit={t("format.unit.person", { count: projectStats?.investorsCount })}
                    loading={isLoadingProjectStats || loading}
                />
            )}
            {project?.tokenTotalPrice && project?.currency && (
                <Stat
                    key="totalPrice"
                    value={convertPriceToTargetCurrency(project.tokenTotalPrice, project.currency, userCurrency, conversionRate)}
                    label={t("projects.detail.stats.totalPrice.label")}
                    unit={userCurrency}
                    loading={loading}
                />
            )}
            {projectStats?.averageInvestment !== 0 && (
                <Stat
                    key="averageInvestment"
                    value={convertPriceInCentsToPrice(projectStats?.averageInvestment)}
                    label={t("projects.detail.stats.averageInvestment.label")}
                    unit={userCurrency}
                    loading={isLoadingProjectStats || loading}
                />
            )}
        </div>
    );
};
