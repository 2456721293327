import type { FC } from "react";

import { Heading, RichText } from "src/components";
import type { Article as ArticleType } from "src/types";
import { cn, getUploadFileName } from "src/utils";
import { ArticleAttributes } from "./ArticleAttributes";

type ArticleProps = ArticleType & {
    className?: string;
};

export const Article: FC<ArticleProps> = ({ tags, updatedAt, className, title, image, content, permissions }) => (
    <section className={cn("mx-auto w-full max-w-3xl", className)}>
        <Heading level={1} className="m-0">
            {title}
        </Heading>
        <div className="flex items-center gap-3" />
        <ArticleAttributes tags={tags} updatedAt={updatedAt} type={permissions?.type} className="my-6" />
        {image && (
            <div className="mb-6 w-full overflow-hidden">
                <img className="w-full rounded-none object-cover" src={image?.url} alt={getUploadFileName(image)} />
            </div>
        )}
        <article>
            <RichText content={content} />
        </article>
    </section>
);
