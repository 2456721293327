import type { FC } from "react";

import { CmsError, PageLayout } from "src/components";
import { useArticlesQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { ArticleRequestTypeEnum, BannerPageEnum } from "src/types";
import { ArticleCard } from "../components";

export const NewsPage: FC = () => {
    const { t } = useTranslation();

    const { data, isLoading, isError, error } = useArticlesQuery({ filters: { type: ArticleRequestTypeEnum.News } });

    const articles = data?.data;

    return (
        <PageLayout title={t("articles.news.title")} loading={isLoading} banner={BannerPageEnum.News}>
            {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
            {articles?.length === 0 ? (
                <p>{t("articles.news.noData")}</p>
            ) : (
                articles?.map((article) => <ArticleCard key={article.id} {...article} />)
            )}
        </PageLayout>
    );
};
