import { type FC, useState } from "react";
import { convertPriceInCentsToPrice } from "utils/number";

import { CmsError, CurrencySelector, NoContent, PageLayout } from "src/components";
import { useUserProjectPortfolioQuery, useUserQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { BannerPageEnum, Currency } from "src/types";
import { DashboardProjects, DashboardStats } from "./components";

export const DashboardPage: FC = () => {
    const { t } = useTranslation();

    const { data: user } = useUserQuery();
    const [currency, setCurrency] = useState(user?.userProfile?.currency ?? Currency.EUR);
    const { data: portfolio, isLoading, isError, error } = useUserProjectPortfolioQuery(currency);

    return (
        <PageLayout
            title={t("dashboard.title")}
            loading={isLoading}
            banner={BannerPageEnum.Dashboard}
            rightCornerComponent={
                <CurrencySelector value={currency} onChange={(value) => setCurrency(value as Currency)} contentPosition="popper" />
            }
        >
            {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
            <div className="flex flex-col gap-12 max-sm:gap-6">
                <div className="space-y-5 font-inter">
                    <span>{t("dashboard.netWorth.title")}</span>
                    <div className="text-6xl">
                        {t("format.currency", { value: convertPriceInCentsToPrice(portfolio?.netWorth), currency })}
                    </div>
                </div>
                <DashboardStats
                    invested={portfolio?.investedAmount ?? 0}
                    currency={currency}
                    noProjects={portfolio?.projectCount ?? 0}
                    noCountries={portfolio?.countriesCount ?? 0}
                />
                {portfolio?.projects && portfolio?.projects?.length > 0 ? (
                    <DashboardProjects projects={portfolio?.projects} currency={currency} />
                ) : (
                    <NoContent transKey={"dashboard.projects.empty"} />
                )}
            </div>
        </PageLayout>
    );
};
