import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React, { useState } from "react";
import { CircleFlag } from "react-circle-flags";

import { ArrowDownIcon, ArrowUpIcon } from "src/assets/icons";
import { Languages, setLanguage, useTranslation } from "src/translations";
import { cn } from "src/utils";

interface Props {
    className?: string;
    label?: string;
    asInput?: boolean;
    position?: "right" | "left" | "center";
}

const getFlagCountryCode = (language: Languages): string => {
    switch (language) {
        case Languages.cs:
            return "cz";
        case Languages.en:
            return "gb";
        default:
            throw new Error(`Unsupported language: ${language}`);
    }
};

const getDropdownAlign = (position: string) => {
    switch (position) {
        case "left":
            return "start";
        case "right":
            return "end";
        default:
            return "center";
    }
};

export const LanguageSelector: React.FC<Props> = ({ className, label, asInput = false, position = "center" }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { t, i18n } = useTranslation();

    return (
        <div className={cn("z-10 flex flex-col space-y-2", position === "right" ? "items-end" : "items-start", className)}>
            {label && <span className="text-sm">{label}</span>}
            <DropdownMenu.Root
                onOpenChange={() => {
                    setIsDropdownOpen(!isDropdownOpen);
                }}
            >
                <DropdownMenu.Trigger asChild>
                    <button
                        type="button"
                        className={cn(
                            "inline-flex flex-row items-center justify-between focus:outline-none sm:w-full",
                            "rounded-main border bg-white p-2 text-left hover:border-hover",
                            asInput ? "focus:border-focused" : "",
                        )}
                    >
                        <span className="hidden px-2 capitalize sm:inline-flex">{t(`common.language.${i18n.language}`)}</span>
                        <CircleFlag className="sm:hidden" width={24} countryCode={getFlagCountryCode(i18n.language as Languages)} />
                        <span className="inline-flex items-center justify-center" style={{ width: 20, height: 20 }}>
                            {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </span>
                    </button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        className={cn(
                            "z-50 rounded-lg border bg-white will-change-[opacity,transform] data-[side=bottom]:animate-dropdownMenuSlideUp data-[side=top]:animate-dropdownMenuSlideDown",
                            asInput ? "w-[--radix-dropdown-menu-trigger-width]" : "w-full",
                        )}
                        sideOffset={2}
                        align={getDropdownAlign(position)}
                    >
                        {Object.values(Languages).map((language) => (
                            <DropdownMenu.Item
                                key={language}
                                onSelect={async () => {
                                    setLanguage(language);
                                }}
                                className={cn(
                                    "flex cursor-pointer items-center gap-2 rounded capitalize focus:outline-none",
                                    asInput ? "p-3" : "px-5 py-3",
                                )}
                            >
                                {<CircleFlag width={24} countryCode={getFlagCountryCode(language)} />}
                                {t(`common.language.${language}`)}
                            </DropdownMenu.Item>
                        ))}
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </div>
    );
};
