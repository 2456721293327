import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { IconWithPopover } from "src/components";
import { InfoIcon } from "src/icons";
import { useTranslation } from "src/translations";

type ReturnOfInvestmentParametersProps = Readonly<{
    incrementalPropertyValue?: number;
    annualRentalIncome?: number;
    loading: boolean;
}>;

export const ReturnOfInvestmentParameters: FC<ReturnOfInvestmentParametersProps> = ({
    incrementalPropertyValue,
    annualRentalIncome,
    loading,
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-start justify-start self-stretch">
            <div className="items-center justify-between self-stretch py-2 opacity-70 md:inline-flex">
                <div className="inline-flex shrink grow basis-0 items-center gap-1 text-base font-normal text-black">
                    {t("projects.detail.sidebar.calculator.roi.incrementalPropertyValue.label")}
                    <IconWithPopover icon={<InfoIcon className="size-4" />}>
                        {t("projects.detail.sidebar.calculator.roi.incrementalPropertyValue.text")}
                    </IconWithPopover>
                </div>
                <div className="text-base font-bold text-black md:text-right">
                    {loading ? (
                        <Skeleton width={100} />
                    ) : (
                        t("projects.detail.sidebar.calculator.roi.incrementalPropertyValue.value", { value: incrementalPropertyValue })
                    )}
                </div>
            </div>
            <div className="items-center justify-between self-stretch py-2 opacity-70 md:inline-flex">
                <div className="inline-flex shrink grow basis-0 items-center gap-1 text-base font-normal text-black">
                    {t("projects.detail.sidebar.calculator.roi.annualRentalIncome.label")}
                    <IconWithPopover icon={<InfoIcon className="size-4" />}>
                        {t("projects.detail.sidebar.calculator.roi.annualRentalIncome.text")}
                    </IconWithPopover>
                </div>
                <div className="text-base font-bold text-black md:text-right">
                    {loading ? (
                        <Skeleton width={100} />
                    ) : (
                        t("projects.detail.sidebar.calculator.roi.annualRentalIncome.value", { value: annualRentalIncome })
                    )}
                </div>
            </div>
        </div>
    );
};
