import type { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

import { LogoutIcon } from "src/icons";
import { Routes } from "src/routes";
import { removeAuthToken, rootApi, useUserQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { useAppDispatch } from "src/utils";
import { Avatar } from "../../Avatar";

export const UserSection: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { data: user, isLoading } = useUserQuery();

    let displayName = user?.username || user?.email;
    if (user?.userProfile?.firstName && user?.userProfile?.lastName) {
        displayName = `${user.userProfile.firstName.trim()} ${user.userProfile.lastName.trim()}`;
    }

    const onLogoutClick = () => {
        removeAuthToken();
        dispatch(rootApi.util.resetApiState());
        navigate(Routes.signIn.path);
    };

    return (
        <div className="flex cursor-default flex-col gap-4 px-6 lg:flex-row lg:items-center lg:justify-between lg:p-6">
            <div className="flex gap-2.5 overflow-hidden">
                {isLoading ? (
                    <>
                        <Skeleton inline height={24} width={24} circle />
                        <Skeleton containerClassName="grow" height={24} />
                    </>
                ) : (
                    <>
                        <Avatar userName={displayName} />
                        <span className="grow truncate font-inter font-medium">{displayName}</span>
                    </>
                )}
            </div>
            <button className="flex gap-2.5" type="button" aria-label="Logout" onClick={onLogoutClick}>
                <LogoutIcon className="size-6 fill-secondary transition-colors duration-300 hover:fill-brand" />
                <span className="lg:hidden">{t("common.logout")}</span>
            </button>
        </div>
    );
};
