import { type BlocksContent, BlocksRenderer } from "@strapi/blocks-react-renderer";
import { type FC } from "react";

import { Heading } from "./Heading";
import { Link } from "./Link";

type RichTextProps = Readonly<{
    content: BlocksContent;
}>;

export const RichText: FC<RichTextProps> = ({ content }) => (
    <BlocksRenderer
        content={content}
        blocks={{
            paragraph: ({ children }) => <p className="my-2 font-inter">{children}</p>,
            heading: ({ children, level }) => (
                <Heading className="mb-3 font-inter" level={level}>
                    {children}
                </Heading>
            ),
            link: ({ children, url }) => (
                <Link className="underline" href={url} isExternal={!url.startsWith(window.location.origin)}>
                    {children}
                </Link>
            ),
            list: ({ children, format }) =>
                format === "ordered" ? (
                    <ol className="mb-2 list-decimal pl-5 font-inter">{children}</ol>
                ) : (
                    <ul className="mb-2 list-disc pl-5 font-inter">{children}</ul>
                ),
            quote: ({ children }) => <blockquote className="text-lg font-semibold italic">{children}</blockquote>,
        }}
    />
);
