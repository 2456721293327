import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { Slider } from "src/components";
import { useTranslation } from "src/translations";
import { DEFAULT_INVESTMENT_AMOUNT } from "./constants";
import { calculateSuitableStep } from "./utils";

type ReturnOfInvestmentCalculationProps = Readonly<{
    investmentAmount: number;
    onInvestmentAmountChange: (newValue: number) => void;
    maxCalculatorAmount?: number;
    minCalculatorAmount?: number;
    currency: string;
    loading: boolean;
}>;

export const ReturnOfInvestmentCalculation: FC<ReturnOfInvestmentCalculationProps> = ({
    investmentAmount,
    onInvestmentAmountChange,
    maxCalculatorAmount,
    minCalculatorAmount,
    currency,
    loading,
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-start justify-start gap-3 self-stretch">
            <div className="text-2xl font-medium leading-8 text-black">{t("projects.detail.sidebar.calculator.roi.calculation.title")}</div>
            <div className="items-start justify-between gap-2 self-stretch md:inline-flex">
                <div className="text-lg font-medium text-brand">{t("projects.detail.sidebar.calculator.roi.calculation.label")}</div>
                <div className="text-2xl font-bold leading-8 text-brand">
                    {loading ? <Skeleton width={60} /> : t("format.currency", { value: investmentAmount, currency })}
                </div>
            </div>

            <Slider
                value={investmentAmount}
                onChange={onInvestmentAmountChange}
                min={minCalculatorAmount}
                max={maxCalculatorAmount ?? DEFAULT_INVESTMENT_AMOUNT}
                step={calculateSuitableStep(minCalculatorAmount, maxCalculatorAmount)}
            />
        </div>
    );
};
