import type { TFunction } from "i18next";
import type { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { TextWithIcon } from "src/components";
import { BuiltIcon, FloorIcon, LayoutIcon, MaterialIcon, RatingIcon, RulerIcon } from "src/icons";
import { useTranslation } from "src/translations";
import { type Project, type ProjectInfoStateEnum, ProjectLayoutComponentMaterialEnum } from "src/types";
import { isNotNullish } from "src/utils";

type ProjectDetailHeaderProps = Readonly<{
    loading: boolean;
    projectState: ProjectInfoStateEnum;
    completedOn: Project["completedOn"];
    layout: Project["layout"];
}>;

export const ProjectDetailHeader: FC<ProjectDetailHeaderProps> = ({ loading, layout, completedOn }) => {
    const { t } = useTranslation();

    const typeLabel = mapProjectLayoutMaterialLabel(t, layout?.material);

    return (
        <div className="grid w-full gap-6">
            <div className="hidden flex-wrap items-center gap-6 fill-primary text-primary lg:flex">
                {loading && (
                    <>
                        <Skeleton width={140} height={16} />
                        <Skeleton width={140} height={16} />
                        <Skeleton width={140} height={16} />
                        <Skeleton width={140} height={16} />
                    </>
                )}
                {!loading && (
                    <>
                        <TextWithIcon
                            icon={<LayoutIcon className="size-6" />}
                            text={`${layout?.bedrooms} ${t("projects.detail.header.numOfBedrooms")}, ${layout?.bathrooms} ${t("projects.detail.header.numOfBathrooms")}`}
                        />

                        <TextWithIcon icon={<RulerIcon className="size-6" />} text={`${t("format.amount", { value: layout?.size })} m2`} />

                        {completedOn && (
                            <TextWithIcon
                                icon={<BuiltIcon className="size-6" />}
                                text={`${t("projects.detail.header.completedOn")} ${t("format.localDate", { date: completedOn })}`}
                            />
                        )}

                        {isNotNullish(layout?.floor) && (
                            <TextWithIcon
                                icon={<FloorIcon className="size-6" />}
                                text={
                                    layout.floor === 0
                                        ? t("projects.detail.header.groundFloor")
                                        : `${t("format.ordinal", { number: layout.floor })} ${t("projects.detail.header.floor")}`
                                }
                            />
                        )}

                        {typeLabel && <TextWithIcon icon={<MaterialIcon className="size-6" />} text={typeLabel} />}

                        <TextWithIcon
                            icon={<RatingIcon className="size-6" />}
                            text={`${layout?.energyClass} ${t("projects.detail.header.rating")}`}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

const mapProjectLayoutMaterialLabel = (t: TFunction, type?: ProjectLayoutComponentMaterialEnum) => {
    switch (type) {
        case ProjectLayoutComponentMaterialEnum.Brick:
            return t("projects.detail.header.material.brick");
        case ProjectLayoutComponentMaterialEnum.Panel:
            return t("projects.detail.header.material.panel");
        case ProjectLayoutComponentMaterialEnum.Wood:
            return t("projects.detail.header.material.wood");
        default:
            return "";
    }
};
