import { cva, type VariantProps } from "class-variance-authority";
import type { FC, HTMLAttributes } from "react";

import type { LocKey } from "src/translations";
import { OrderStateEnum } from "src/types";
import { cn } from "src/utils";

const badgeStyles = cva("inline-flex max-h-9 items-center justify-center gap-1 rounded-full px-3.5 py-1.5 text-sm font-medium", {
    variants: {
        intent: {
            neutral: "bg-gray-200 text-secondary",
            slate: "bg-slate-500 text-white",
            purple: "bg-purple-500 text-white",

            success: "bg-green-500 text-white",
            danger: "bg-red-500 text-white",
            warning: "bg-yellow-500 text-primary",
            link: "bg-blue-500 text-white",

            subtleSuccess: "bg-green-200 fill-green-800 text-green-800",
            subtleDanger: "bg-red-200 fill-red-800 text-red-800",
            subtleWarning: "bg-yellow-200 fill-brand text-primary",
            subtleLink: "bg-blue-200 fill-brand text-brand-primary",
        },
    },
    defaultVariants: {
        intent: "neutral",
    },
});

type BadgeProps = HTMLAttributes<HTMLDivElement> & VariantProps<typeof badgeStyles>;

export type BadgeIntent = BadgeProps["intent"];

export const Badge: FC<BadgeProps> = ({ className, intent, children, ...props }) => (
    <div className={cn(badgeStyles({ intent }), className)} {...props}>
        {children}
    </div>
);

export const orderStateBadgeMap: Record<OrderStateEnum, { intent: BadgeIntent; titleLocKey: LocKey; additionalInfoLocKey?: LocKey }> = {
    [OrderStateEnum.Open]: {
        intent: "subtleLink",
        titleLocKey: "orders.state.open.title",
    },
    [OrderStateEnum.Unpaid]: {
        intent: "subtleWarning",
        titleLocKey: "orders.state.unpaid.title",
        additionalInfoLocKey: "orders.state.unpaid.additionalInfo",
    },
    [OrderStateEnum.Failed]: {
        intent: "subtleDanger",
        titleLocKey: "orders.state.failed.title",
        additionalInfoLocKey: "orders.state.failed.additionalInfo",
    },
    [OrderStateEnum.Paid]: {
        intent: "subtleSuccess",
        titleLocKey: "orders.state.paid.title",
    },
    [OrderStateEnum.Canceled]: {
        intent: "subtleDanger",
        titleLocKey: "orders.state.canceled.title",
        additionalInfoLocKey: "orders.state.canceled.additionalInfo",
    },
    [OrderStateEnum.AdminCancel]: {
        intent: "subtleDanger",
        titleLocKey: "orders.state.canceled.title",
        additionalInfoLocKey: "orders.state.canceled.additionalInfo",
    },
    [OrderStateEnum.Timeout]: {
        intent: "subtleDanger",
        titleLocKey: "orders.state.timeout.title",
        additionalInfoLocKey: "orders.state.timeout.additionalInfo",
    },
} as const;
