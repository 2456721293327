import type { FC } from "react";

import { Button, CmsError, PopupWindow } from "src/components";
import type { Logger } from "src/logging";
import {
    getUser,
    isCmsErrorResponseWithMessage,
    useCreateFundedRequestMutation,
    useHasUserFundedRequestForProjectDocumentIdQuery,
} from "src/serverApi";
import { useTranslation } from "src/translations";
import type { DocumentId } from "src/types";
import { useAppSelector } from "src/utils";

export type FundedRequestButtonProps = Readonly<{
    loading: boolean;
    projectDocumentId: DocumentId;
    logger: Logger;
}>;

export const FundedRequestButton: FC<FundedRequestButtonProps> = ({ loading, projectDocumentId, logger }) => {
    const { t } = useTranslation();

    const user = useAppSelector(getUser);

    const {
        data: hasAlreadySentRequest,
        isLoading: hasAlreadySendRequestLoading,
        isError,
        error,
    } = useHasUserFundedRequestForProjectDocumentIdQuery({
        userId: user.id!,
        projectDocumentId,
    });

    const [createFundedRequest, { isLoading: isCreateFundedRequestLoading }] = useCreateFundedRequestMutation();

    const handleOnClick = async () => {
        if (user.documentId) {
            try {
                await createFundedRequest({ data: { user: user.documentId, project: projectDocumentId } }).unwrap();
                await PopupWindow.fire({
                    title: t("projects.fundedRequest.button.success.title"),
                    text: t("projects.fundedRequest.button.success.text"),
                    icon: "success",
                });
            } catch (err) {
                logger.error(isCmsErrorResponseWithMessage(err) ? err.data.error.message : "Unknown error", err);
                await PopupWindow.fire({
                    title: t("projects.fundedRequest.button.error.title"),
                    text: t("projects.fundedRequest.button.error.text"),
                    icon: "error",
                });
            }
        }
    };

    if (isError) return <CmsError error={error} fallbackMessage={t("common.error")} />;
    return (
        <Button
            disabled={hasAlreadySentRequest}
            isLoading={loading || hasAlreadySendRequestLoading || isCreateFundedRequestLoading}
            fullWidth
            onClick={handleOnClick}
        >
            {hasAlreadySentRequest ? t("projects.fundedRequest.button.alreadySent") : t("projects.fundedRequest.button.text")}
        </Button>
    );
};
