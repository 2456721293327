import { type FC, Fragment, useMemo } from "react";

import { CmsError, Heading, PopupWindow, RadioGroup, RadioGroupItem } from "src/components";
import { getUser, isCmsErrorResponseWithMessage, useVoteMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { type Voting, VotingStateEnum } from "src/types";
import { cn, useAppSelector } from "src/utils";
import { logger } from "../logger";
import { VotingCardQuestionStatBar } from "./VotingCardQuestionStatBar";

type VotingCardQuestionProps = {
    votingItem: Voting;
    className?: string;
};

export const VotingCardQuestion: FC<VotingCardQuestionProps> = ({ votingItem, className }) => {
    const { t } = useTranslation();

    const user = useAppSelector(getUser);

    const [vote, { isLoading, isError, error }] = useVoteMutation();

    const userVotedOptionKey = useMemo(() => {
        const votedOption = votingItem.results?.find((result) => result?.user?.id === user?.id);
        return votedOption?.optionKey ?? undefined;
    }, [user?.id, votingItem.results]);

    const totalVoteWeight = useMemo(
        () => votingItem.results?.reduce((acc, result) => acc + (result?.weight ?? 1), 0),
        [votingItem.results],
    );

    const handleVote = async (optionKey: string) => {
        if (votingItem.state !== VotingStateEnum.Current) return;
        try {
            await vote({
                optionKey,
                votingDocumentId: votingItem.documentId!,
            }).unwrap();
            await PopupWindow.fire({
                title: t("voting.card.vote.new.title"),
                text: t("voting.card.vote.new.text"),
            });
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    return (
        <div className={cn("w-full", className)}>
            <Heading level={5} className="mb-3">
                {votingItem.question}
            </Heading>
            <RadioGroup orientation="vertical" onValueChange={handleVote} value={userVotedOptionKey} className="gap-3">
                {votingItem?.options?.map(({ option, key }) => {
                    const results = votingItem.results?.filter((result) => result?.optionKey === key);
                    const voteWeightForOption = results?.reduce((acc, result) => acc + (result?.weight ?? 1), 0);
                    return key ? (
                        <Fragment key={key}>
                            <RadioGroupItem
                                className="border-brand-primary"
                                value={key}
                                disabled={votingItem.state !== VotingStateEnum.Current || isLoading}
                            >
                                {option}
                            </RadioGroupItem>
                            <VotingCardQuestionStatBar
                                totalVoteWeight={totalVoteWeight}
                                voteWeightForOption={voteWeightForOption}
                                className="-mt-3 ml-7"
                            />
                        </Fragment>
                    ) : null;
                })}
            </RadioGroup>
            {isError && <CmsError className="mt-6" error={error} fallbackMessage={t("common.error")} />}
        </div>
    );
};
