import type { Banner, BannerListResponse, BannerPageEnum } from "src/types";
import { HttpMethod } from "./constants";
import { rootApi } from "./rootApi";
import { BANNER_TAG, ENTITY_WITH_TRANSLATIONS_TAG } from "./tags";
import { getCmsUrl } from "./utils";

export const bannerApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        banner: build.query<Banner | undefined, BannerPageEnum>({
            query: (page) => ({
                url: getCmsUrl("/api/banners"),
                method: HttpMethod.GET,
                params: { ofPage: page },
            }),
            providesTags: [BANNER_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
            transformResponse: (response: BannerListResponse) => response.data?.[0],
        }),
    }),
});

export const { useBannerQuery } = bannerApi;
