import { cva, type VariantProps } from "class-variance-authority";
import { type ForwardedRef, forwardRef, type InputHTMLAttributes } from "react";
import type { FieldError } from "react-hook-form";

import { InfoIcon } from "src/icons";
import { useTranslation } from "src/translations";
import { cn } from "src/utils";
import { IconWithPopover } from "../IconWithPopover";
import { getErrorMessage } from "../utils";

const inputStyles = cva(
    "h-10 rounded-main p-2 font-medium transition-colors duration-200 ease-in-out placeholder:text-tertiary focus:outline-none disabled:border-primary disabled:bg-disabled disabled:text-disabled",
    {
        variants: {
            bsSize: {
                default: "px-3 py-1.5 text-base leading-[1.6rem]",
                large: "px-5 py-[0.4375rem] text-lg leading-[1.85625rem]",
                compact: "px-3 py-1.5 text-sm leading-[1.26875rem]",
            },
            kind: {
                primary: "border border-primary hover:border-hover focus:border-focused",
                error: "border !border-error focus:!border-error",
                outline: "",
            },
        },
        defaultVariants: {
            bsSize: "default",
            kind: "primary",
        },
    },
);

type InputProps = Readonly<{
    label?: string;
    optional?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    error?: string | FieldError;
    additionalInfo?: string;
    ref?: ForwardedRef<HTMLInputElement>;
}> &
    InputHTMLAttributes<HTMLInputElement> &
    VariantProps<typeof inputStyles>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ kind, bsSize, className, fullWidth, type, disabled, label, optional, error, additionalInfo, ...props }, ref) => {
        const { t } = useTranslation();

        return (
            <div className="flex w-full flex-col gap-y-2">
                {(label || additionalInfo) && (
                    <div className="flex items-center gap-x-1">
                        {label && (
                            <label className="flex gap-2 text-sm text-primary">
                                {label}
                                {optional && <span className="text-primary">({t("common.optional")})</span>}
                            </label>
                        )}
                        {additionalInfo && (
                            <IconWithPopover icon={<InfoIcon className="size-4 fill-primary" />}>{additionalInfo}</IconWithPopover>
                        )}
                    </div>
                )}
                <input
                    className={cn(inputStyles({ kind: error ? "error" : kind, bsSize }), fullWidth ? "w-full" : "inline-flex", className)}
                    type={type || "text"}
                    disabled={disabled}
                    ref={ref}
                    {...props}
                />
                {error && <span className={cn("text-sm text-error")}>{getErrorMessage(error)}</span>}
            </div>
        );
    },
);
Input.displayName = "Input";
