import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { CmsError, PageLayout } from "src/components";
import { Routes } from "src/routes";
import { useArticleByDocumentIdQuery } from "src/serverApi";
import { useDocumentIdFromSlug, useTranslation } from "src/translations";
import { getValOrThrowError } from "src/utils";
import { Article } from "../components";

export const ArticleDetailPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const articleSlug = getValOrThrowError(Routes.articleDetail.useParams().articleSlug, "Article slug is required");

    const articleDocumentId = useDocumentIdFromSlug(
        articleSlug,
        "api::article.article",
        (composedSlug) => {
            navigate(Routes.articleDetail.fillPathParams({ articleSlug: composedSlug }), { replace: true });
        },
        () => {
            navigate(Routes.projects.path);
        },
    );

    const { data, isLoading, isError, error } = useArticleByDocumentIdQuery(articleDocumentId!, { skip: !articleDocumentId });

    return (
        <PageLayout loading={isLoading || !articleDocumentId}>
            {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
            {data && <Article {...data} />}
        </PageLayout>
    );
};
