import type { FC } from "react";
import { CircleFlag } from "react-circle-flags";

import { Badge, type BadgeIntent } from "src/components";
import { type LocKey, useTranslation } from "src/translations";
import { ProjectInfoStateEnum } from "src/types";

type ProjectBadgesProps = Readonly<{
    projectState: ProjectInfoStateEnum;
    countryCode?: string;
}>;

export const ProjectBadges: FC<ProjectBadgesProps> = ({ projectState, countryCode }) => {
    const { t } = useTranslation();

    if (!countryCode || !projectState) return null;

    const { intent, locKey } = projectStateBadgesMap[projectState];

    return (
        <div className="flex items-center gap-3">
            <Badge intent={intent}>{t(locKey)} </Badge>
            <span className="flex items-center gap-2">
                <CircleFlag width={24} countryCode={countryCode.toLowerCase()} />
                <span className="text-[12px] font-medium uppercase">{t("format.region", { countryCode })}</span>
            </span>
        </div>
    );
};

const projectStateBadgesMap: Record<ProjectInfoStateEnum, { intent: BadgeIntent; locKey: LocKey }> = {
    [ProjectInfoStateEnum.Closed]: { intent: "danger", locKey: "projects.badges.projectState.closed" },
    [ProjectInfoStateEnum.Open]: { intent: "success", locKey: "projects.badges.projectState.open" },
    [ProjectInfoStateEnum.Prepared]: { intent: "warning", locKey: "projects.badges.projectState.soon" },
    [ProjectInfoStateEnum.Unknown]: { intent: "neutral", locKey: "projects.badges.projectState.soon" },
    [ProjectInfoStateEnum.Completed]: { intent: "danger", locKey: "projects.badges.projectState.completed" },
} as const;
