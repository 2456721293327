import type { FC } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { PageLayout } from "src/components";
import { getAppConfig } from "src/config";
import { Routes } from "src/routes";
import { useProjectInfoDetailByDocumentIdQuery } from "src/serverApi";
import { useDocumentIdFromSlug } from "src/translations";
import { ProjectInfoStateEnum } from "src/types";
import { getValOrThrowError, useAppSelector } from "src/utils";
import { CTA, ProjectBadges } from "../components";
import {
    ProjectDetailDescription,
    ProjectDetailGallery,
    ProjectDetailHeader,
    ProjectDetailLayout,
    ProjectDetailSidebar,
    ProjectDetailStats,
    ProjectDetailTimeline,
} from "./components";
import { logger } from "./logger";
import { useScrollToTop } from "./utils";

export const ProjectDetailPage: FC = () => {
    useScrollToTop();
    const params = Routes.projectDetail.useParams();
    const projectSlug = getValOrThrowError(params.projectSlug, "Project slug is required");
    const appConfig = getValOrThrowError(useAppSelector(getAppConfig));
    const navigate = useNavigate();

    const projectInfoDocumentId = useDocumentIdFromSlug(
        projectSlug,
        "api::project-info.project-info",
        (composedSlug) => {
            navigate(Routes.projectDetail.fillPathParams({ projectSlug: composedSlug }), { replace: true });
        },
        () => {
            navigate(Routes.projects.path);
        },
    );

    const {
        data: projectInfo,
        isLoading,
        isFetching,
    } = useProjectInfoDetailByDocumentIdQuery(projectInfoDocumentId!, { skip: !projectInfoDocumentId });
    const project = projectInfo?.project;
    const projectState = project?.state ?? ProjectInfoStateEnum.Unknown;

    const loading = isLoading || isFetching || !projectInfoDocumentId;

    return (
        <PageLayout
            title={projectInfo?.title}
            titleLoading={loading}
            rightCornerComponent={<ProjectBadges projectState={projectState} countryCode={project?.countryCode} />}
            titleClassName="sm:max-w-[1250px] mx-auto w-full"
        >
            <Helmet>
                <title>{projectInfo?.slug ? `${projectInfo?.slug} | ${appConfig.title}` : appConfig.title}</title>
            </Helmet>
            <div className="mx-auto grid w-full max-w-[1250px] grid-flow-row sm:gap-y-12 md:gap-y-16 xl:relative">
                <CTA
                    className="fixed bottom-6 left-1/2 z-[25] w-[80vw] max-w-72 -translate-x-1/2 lg:hidden"
                    isFloating
                    logger={logger}
                    loading={loading}
                    projectSlug={projectSlug}
                    projectDocumentId={project?.documentId!}
                    projectState={projectState}
                />
                <ProjectDetailHeader
                    loading={loading}
                    projectState={projectState}
                    completedOn={project?.completedOn}
                    layout={project?.layout}
                />
                <ProjectDetailLayout
                    gallery={<ProjectDetailGallery loading={loading} projectImages={project?.images ?? []} />}
                    timeline={<ProjectDetailTimeline loading={loading} {...project} />}
                    stats={<ProjectDetailStats loading={loading} projectState={projectState} projectInfo={projectInfo} />}
                    description={<ProjectDetailDescription loading={loading} content={projectInfo?.description ?? []} />}
                    sidebar={<ProjectDetailSidebar loading={loading} projectInfo={projectInfo} />}
                />
            </div>
        </PageLayout>
    );
};
