import type { FC } from "react";

import { Languages, setLanguage, useTranslation } from "src/translations";
import { cn } from "src/utils";

type LanguageSwitcherProps = Readonly<{
    className?: string;
}>;

export const LanguageSwitcher: FC<LanguageSwitcherProps> = ({ className }) => {
    const { t, i18n } = useTranslation();

    return (
        <div className={cn("prose-text-default-strong mt-auto flex gap-4 p-6", className)}>
            {Object.values(Languages).map((lang) => (
                <button
                    type="button"
                    key={lang}
                    onClick={() => setLanguage(lang)}
                    className={cn({ "text-tertiary": i18n.language !== lang })}
                >
                    {t(`common.language.${lang}`)}
                </button>
            ))}
        </div>
    );
};
