import type { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useUpdateProfileUser } from "src/app/public/utils";
import { Button, ButtonWithIcon, CmsError, Heading, Input, Link, SecretInput, Separator } from "src/components";
import { GoogleIcon } from "src/icons";
import { Routes } from "src/routes";
import { getCmsUrl, isCmsErrorResponseWithMessage, removeAuthToken, setAuthToken, useLoginMutation } from "src/serverApi";
import { useTranslation } from "src/translations";
import { logGtmEvent } from "src/utils";
import { logger } from "./logger";

type FormFields = {
    identifier: string;
    password: string;
};

export const SignUpForm: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const [login, { isLoading, isError, error }] = useLoginMutation();

    const updateUserProfile = useUpdateProfileUser();

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<FormFields>();

    const onSubmit = async (data: FormFields) => {
        try {
            removeAuthToken();
            const { jwt } = await login(data).unwrap();
            setAuthToken(jwt);
            updateUserProfile().then(() => {
                const next = searchParams.get("next");
                if (next) {
                    navigate(next);
                } else {
                    navigate(Routes.projects.path);
                }
            });
        } catch (err) {
            if (isCmsErrorResponseWithMessage(err)) {
                logger.error(err.data.error.message, err);
            } else {
                logger.error("Unknown error", err);
            }
        }
    };

    const handleGoogleLogin = async () => {
        try {
            logGtmEvent({ event: "user_google_login_triggered" });
            window.location.href = getCmsUrl("/api/connect/google");
        } catch (err) {
            logger.error("Google login error", err);
        }
    };

    return (
        <div className="w-full space-y-6">
            <Heading level={2} className="mb-8">
                {t("signIn.title")}
            </Heading>
            {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
            <ButtonWithIcon onClick={handleGoogleLogin} isLoading={isLoading} icon={<GoogleIcon />} className="bg-black hover:bg-black">
                {t("signIn.button.google")}
            </ButtonWithIcon>

            <form id="Login" onSubmit={handleSubmit(onSubmit)} className="w-full space-y-[40px]">
                <div className="space-y-6">
                    <Separator />
                    <Input
                        {...register("identifier", { required: true })}
                        type="text"
                        maxLength={70}
                        label={t("signIn.placeholder.email")}
                        error={errors.identifier?.message}
                        autoComplete="email"
                        fullWidth
                    />

                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <span className="text-sm">{t("signIn.placeholder.password")}</span>
                            <Link size="compact" href={Routes.forgotPassword.path}>
                                {t("signIn.forgotPassword.link")}
                            </Link>
                        </div>
                        <SecretInput
                            {...register("password", { required: true })}
                            error={errors.password?.message}
                            fullWidth
                            autoComplete="current-password"
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-5">
                    <Button type="submit" isLoading={isLoading} fullWidth disabled={!isValid}>
                        {t("signIn.button.login")}
                    </Button>
                    <div className="flex flex-col items-center justify-center gap-2 md:flex-row">
                        <span className="text-sm">{t("signIn.register.label")}</span>
                        <Link href={Routes.signUp.path}>{t("signIn.register.link")}</Link>
                    </div>
                </div>
            </form>
        </div>
    );
};
