import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { getNumberSeparators, isNotNullish, type NumberSeparators } from "src/utils";
import type { LocKey } from "./types";

// re export it for future improvements
export { Trans, useTranslation } from "react-i18next";

/**
 * Gets text from value (displayed as is, without need of translation, for example name from entity) or from localization key.
 * This is useful if text can be specified in both ways. At least one of them is mandatory. If both are specified, then value has
 * higher priority and is returned.
 * @param t T-function for translation of key.
 * @param value Value to return or undefined /null.
 * @param key Key to translate or undefined /null.
 * @throws Throws error when both value and key are nullish.
 */
export const getTextFromValueOrKey = (t: TFunction, value?: string | null, key?: LocKey | null): string => {
    if (isNotNullish(value)) {
        return value;
    }
    if (isNotNullish(key)) {
        return t(key);
    }

    throw new Error("Cannot get text. Both value and key are not defined. At least one of them must be defined.");
};

/**
 * Provides decimal and thousands separators for current language.
 */
export const useNumberSeparators = (): NumberSeparators => {
    const { i18n } = useTranslation();
    return getNumberSeparators(i18n.language);
};

export const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case "CZK":
            return "Kč";
        case "EUR":
            return "€";
        default:
            return currency;
    }
};
