import type { FC } from "react";

import { useTranslation } from "src/translations";
import { UserVerificationTypeEnum } from "src/types";
import { cn } from "src/utils";
import { useUserVerification } from "src/verification";
import { StepItem } from "./StepItem";

export const HowItWorksStepper: FC = () => {
    const { t } = useTranslation();
    const { verificationOptions, nextVerification, isVerificationDone } = useUserVerification();

    const isPreviousStep = (relevance: number) => relevance < nextVerification.relevance;
    const isActiveStep = (relevance: number) => relevance === nextVerification.relevance;
    const isNextStep = (relevance: number) => relevance > nextVerification.relevance;

    if (isVerificationDone(UserVerificationTypeEnum.INVESTOR)) return null;

    return (
        <div className="flex flex-col gap-4">
            <h5 className="hidden text-2xl lg:block">{t("projects.detail.sidebar.howItWorksStepper.title")}</h5>
            <div className="z-10 hidden flex-col gap-8 lg:block">
                {verificationOptions?.map(({ id, ...verification }) => (
                    <StepItem
                        key={id}
                        isActive={verification?.type === nextVerification.type}
                        type={verification?.type ?? UserVerificationTypeEnum.SIGNUP}
                        name={verification?.name ?? ""}
                        description={verification?.description ?? ""}
                        timeToComplete={verification?.timeToComplete ?? 0}
                        className={cn({
                            "fill-tertiary text-tertiary": isPreviousStep(verification?.relevance ?? 0),
                            "fill-brand text-brand": isActiveStep(verification?.relevance ?? 0),
                            "fill-secondary text-secondary": isNextStep(verification?.relevance ?? 0),
                        })}
                    />
                ))}
            </div>
        </div>
    );
};
