import { type FC, useMemo } from "react";

import { Loading, TopBar, VerificationStepper } from "src/components";
import { Routes } from "src/routes";
import { UserVerificationTypeEnum } from "src/types";
import { Aml, Kyc } from "./components";
import { useVerificationRedirect } from "./utils";

export const VerificationPage: FC = () => {
    const { verificationType } = Routes.verifications.useParams();

    useVerificationRedirect();

    const VerificationComponent = useMemo(() => {
        if (verificationType === UserVerificationTypeEnum.KYC) {
            return <Kyc />;
        }
        if (verificationType === UserVerificationTypeEnum.AML) {
            return <Aml />;
        }
        return <Loading />;
    }, [verificationType]);

    return (
        <div className="min-h-screen">
            <div className="sticky top-0 bg-brand-white">
                <VerificationStepper activeVerificationType={verificationType as UserVerificationTypeEnum} />
                <TopBar />
            </div>
            {VerificationComponent}
        </div>
    );
};
