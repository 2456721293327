import { useCallback, useEffect, useRef } from "react";

import { CAMPAIGN_ID, EHUB_SCRIPT_URL } from "./constants";
import { logger } from "./logger";

declare global {
    interface Window {
        ehubSaleReady: Promise<boolean>;
        EhubSale: new () => EhubSale;
    }

    class EhubSale {
        setCampaignId(campaignId: string): void;
        setOrderId(orderId: string): void;
        process(): void;
    }
}

export const useEhubTracking = (userId: number | undefined) => {
    const trackingPixel = useRef<HTMLImageElement | null>(null);

    const processEhubSale = useCallback(async () => {
        if (!userId || !localStorage.getItem("ehub")) {
            return;
        }

        trackingPixel.current = document.createElement("img");
        trackingPixel.current.id = "ehp";
        trackingPixel.current.src = `${EHUB_SCRIPT_URL}?campaignId=${CAMPAIGN_ID}&orderId=${userId}`;
        trackingPixel.current.width = 1;
        trackingPixel.current.height = 1;
        trackingPixel.current.style.display = "none";
        document.body.appendChild(trackingPixel.current);

        try {
            await window.ehubSaleReady;
            if (!window.EhubSale) {
                throw new Error("EhubSale not loaded");
            }

            const ehubSale = new window.EhubSale();
            ehubSale.setCampaignId(CAMPAIGN_ID);
            ehubSale.setOrderId(userId.toString());
            ehubSale.process();

            localStorage.removeItem("ehub");
        } catch (error) {
            logger.error("Failed to process EhubSale:", error);
        }
    }, [userId]);

    useEffect(() => {
        processEhubSale();
        return () => {
            trackingPixel.current?.remove();
        };
    }, [processEhubSale]);
};
