import type { FC } from "react";
import Skeleton from "react-loading-skeleton";
import type { ReadonlyDeep } from "type-fest";

import { useGetAvailableTokensCountByProjectDocumentIdQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { type ProjectInfo, ProjectInfoStateEnum } from "src/types";
import { daysFromToday, isNullish } from "src/utils";
import { calculateSharePercentage } from "../../utils";
import { PERCENT_INVESTED_TO_SHOW_PROGRESS_BAR } from "../constants";

type ProjectCardMetricsProps = ReadonlyDeep<
    ProjectInfo["project"] & {
        projectState: ProjectInfoStateEnum;
    }
>;

export const ProjectCardMetrics: FC<ProjectCardMetricsProps> = ({
    documentId,
    projectState,
    expectedOn,
    closedOn,
    completedOn,
    calculation,
    tokenTotalCount,
}) => {
    const { t } = useTranslation();
    const { data: availableTokensCount, isLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(documentId!, {
        skip: !documentId,
    });

    if (isLoading)
        return (
            <div className="flex max-lg:flex-col">
                <div className="flex w-full items-center gap-3">
                    <Skeleton className="h-20" containerClassName="flex-1" />
                    <Skeleton className="h-20" containerClassName="flex-1" />
                    <Skeleton className="h-20" containerClassName="flex-1" />
                </div>
            </div>
        );

    const { paReturn, period } = calculation || {};
    const investedPercent = tokenTotalCount ? calculateSharePercentage(availableTokensCount ?? 0, tokenTotalCount) : 0;

    return (
        <>
            {investedPercent >= PERCENT_INVESTED_TO_SHOW_PROGRESS_BAR && (
                <div className="flex flex-col gap-3 font-inter">
                    {projectState === ProjectInfoStateEnum.Open ? (
                        <>
                            <span className="font-bold">
                                {t("format.percent", { value: investedPercent })} {t("projects.list.card.metrics.invested")}
                            </span>
                            <div className="h-2 overflow-x-hidden rounded-main bg-subtle">
                                <div style={{ width: `${investedPercent}%` }} className="h-2 rounded-l-main bg-brand-primary" />
                            </div>
                        </>
                    ) : (
                        <>
                            {expectedOn && projectState === ProjectInfoStateEnum.Prepared && (
                                <span>
                                    {t("projects.list.card.metrics.expectedOn")}{" "}
                                    <span className="font-bold">{t("format.localDate", { date: expectedOn })}</span>
                                </span>
                            )}
                            {completedOn && projectState === ProjectInfoStateEnum.Completed && (
                                <span>
                                    {t("projects.list.card.metrics.completedOn")}{" "}
                                    <span className="font-bold">{t("format.localDate", { date: completedOn })}</span>
                                </span>
                            )}
                            {closedOn && projectState === ProjectInfoStateEnum.Closed && (
                                <span>
                                    {t("projects.list.card.metrics.closedOn")}{" "}
                                    <span className="font-bold">{t("format.localDate", { date: closedOn })}</span>
                                </span>
                            )}
                            <div className="h-2 overflow-x-hidden rounded-main bg-subtle" />
                        </>
                    )}
                </div>
            )}
            {![expectedOn, paReturn, period, investedPercent].every(isNullish) && (
                <div className="grid auto-cols-fr grid-flow-col grid-rows-1 items-start max-sm:-mx-2 [&>div:first-child]:rounded-l-lg [&>div:last-child]:rounded-r-lg [&>div:last-child]:border-r [&>div]:border [&>div]:border-r-0">
                    {projectState === ProjectInfoStateEnum.Prepared && expectedOn && (
                        <Stat
                            label={t("projects.list.card.metrics.untilOpen")}
                            value={`${daysFromToday(expectedOn)} ${t("format.unit.day", { count: daysFromToday(expectedOn) })}`}
                        />
                    )}
                    {paReturn && <Stat label={t("projects.list.card.metrics.return")} value={t("format.percent", { value: paReturn })} />}
                    {investedPercent !== null && (
                        <Stat label={t("projects.list.card.metrics.invested")} value={t("format.percent", { value: investedPercent })} />
                    )}
                    {period && (
                        <Stat
                            label={t("projects.list.card.metrics.period")}
                            value={`${period} ${t("format.unit.year", { count: period })}`}
                        />
                    )}
                </div>
            )}
        </>
    );
};

const Stat: FC<{ label: string; value: string }> = ({ label, value }) => (
    <div className="flex h-full flex-1 flex-col items-center p-2 text-center">
        <h4 className="whitespace-nowrap font-manrope text-2xl font-black text-brand-primary max-sm:text-base max-sm:font-medium">
            {value}
        </h4>
        <span className="font-inter max-sm:text-[0.65rem] max-sm:font-medium max-sm:leading-[snug]">{label}</span>
    </div>
);
