import type { FC } from "react";

import { useTranslation } from "src/translations";

export const Separator: FC = () => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center">
            <hr className="grow border-t border-primary" />
            <span className="mx-4 text-disabled">{t("common.or")}</span>
            <hr className="grow border-t border-primary" />
        </div>
    );
};
