import type { FC } from "react";
import { useState } from "react";

import type { Message as MessageType } from "src/types";
import { cn } from "src/utils";
import { MessageCountDown } from "./MessageCountDown";

type MessageProps = Readonly<{
    text: MessageType["text"];
    countDownTill?: Date;
}>;

export const Message: FC<MessageProps> = ({ text, countDownTill }) => {
    const [visible, setVisible] = useState(true);

    if (!visible) return null;

    return (
        <div
            className={cn(
                "top-0 z-30 grid grid-flow-row items-center justify-items-center text-balance bg-brand p-4 text-sm font-light text-white sm:sticky sm:grid-flow-col sm:grid-cols-[minmax(auto,_1fr)_auto_minmax(auto,_1fr)]",
                { "gap-2 sm:gap-4": !!countDownTill },
            )}
        >
            <div className="sm:justify-self-end">
                {countDownTill && <MessageCountDown onCountDownEnd={() => setVisible(false)} countDownTill={countDownTill} />}
            </div>
            <div className="text-center sm:text-left">{text}</div>
        </div>
    );
};
