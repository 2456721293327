import type { FC } from "react";

import { CmsError, NoContent, PageLayout } from "src/components";
import { useProjectsInfoQuery } from "src/serverApi";
import { useTranslation } from "src/translations";
import { BannerPageEnum } from "src/types";
import { ProjectCard } from "./components";

export const ProjectsPage: FC = () => {
    const { t } = useTranslation();
    const { data: projectsInfo, isLoading, isFetching, isError, error } = useProjectsInfoQuery({});

    const loading = isLoading || isFetching;

    return (
        <PageLayout title={t("projects.list.title")} loading={loading} banner={BannerPageEnum.Projects}>
            {isError ? <CmsError error={error} fallbackMessage={t("common.error")} /> : null}
            {!isError && !loading && !projectsInfo?.data?.length && <NoContent transKey="projects.list.noProjects" />}
            <div className="mx-auto w-full max-w-[1250px] space-y-8">
                {projectsInfo?.data?.map((projectInfo) => <ProjectCard key={projectInfo.id} projectInfo={projectInfo} />)}
            </div>
        </PageLayout>
    );
};
