import type { FC } from "react";

import { Badge, type BadgeIntent } from "src/components";
import { useTranslation } from "src/translations";
import type { Article } from "src/types";
import { ArticlePermissionsComponentTypeEnum1 } from "src/types";
import { cn } from "src/utils";
import ArticleTags from "./ArticleTags";

export type ArticleAttributesProps = {
    type?: ArticlePermissionsComponentTypeEnum1;
    tags?: Article["tags"];
    className?: string;
    updatedAt: Article["updatedAt"];
};

export const ArticleAttributes: FC<ArticleAttributesProps> = ({ updatedAt, tags, className, type }) => {
    const { t } = useTranslation();

    const permissionType = type ? articleTypeMap[type] : undefined;

    return (
        <div className={cn("flex items-center gap-3", className)}>
            {permissionType && <Badge intent={permissionType.intent}>{t(permissionType.titleKey)}</Badge>}
            <ArticleTags tags={tags} />
            {updatedAt && <p>{t("format.localDate", { date: updatedAt })}</p>}
        </div>
    );
};

const articleTypeMap: Record<ArticlePermissionsComponentTypeEnum1, { titleKey: string; intent: BadgeIntent }> = {
    [ArticlePermissionsComponentTypeEnum1.Public]: {
        titleKey: "articles.type.public",
        intent: "slate",
    },
    [ArticlePermissionsComponentTypeEnum1.OnlyInvestors]: {
        titleKey: "articles.type.investor",
        intent: "subtleSuccess",
    },
    [ArticlePermissionsComponentTypeEnum1.Private]: {
        titleKey: "articles.type.private",
        intent: "purple",
    },
};
