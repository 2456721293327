import { cva, type VariantProps } from "class-variance-authority";
import type { FC, ReactNode } from "react";

import { cn } from "src/utils";

const textWithIconStyles = cva("", {
    variants: {
        textSize: {
            xs: "text-xs",
            sm: "text-sm",
            base: "text-base",
            lg: "text-lg",
        },
    },
    defaultVariants: {
        textSize: "base",
    },
});

type TextWithIconProps = Readonly<{
    icon: ReactNode;
    text?: string;
}> &
    React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof textWithIconStyles>;

export const TextWithIcon: FC<TextWithIconProps> = ({ icon, text, textSize, className, ...props }) => (
    <div className={cn("inline-flex items-center justify-center gap-2", className)} {...props}>
        {icon}
        <div className={textWithIconStyles({ textSize })}>{text}</div>
    </div>
);
