import type { FC } from "react";

import { useGetAvailableTokensCountByProjectDocumentIdQuery } from "src/serverApi";
import { ProjectInfoStateEnum } from "src/types";
import { isProjectInInvestState } from "../detail/utils";
import { FundedRequestButton, type FundedRequestButtonProps } from "./FundedRequestButton";
import { InvestButton, type InvestButtonProps } from "./InvestButton";

type CTAProps = Readonly<InvestButtonProps & FundedRequestButtonProps & { className: string; projectState: ProjectInfoStateEnum }>;

export const CTA: FC<CTAProps> = ({ projectDocumentId, logger, loading, className, projectState, ...restInvestProps }) => {
    const isInInvestState = isProjectInInvestState(projectState);
    const { data: availableTokenCount, isLoading: areTokensLoading } = useGetAvailableTokensCountByProjectDocumentIdQuery(
        projectDocumentId!,
        { skip: !projectDocumentId || !isInInvestState },
    );

    if (!isInInvestState) {
        return null;
    }

    return (
        <div className={className}>
            {availableTokenCount ? (
                <InvestButton {...restInvestProps} />
            ) : (
                projectDocumentId && (
                    <FundedRequestButton projectDocumentId={projectDocumentId} loading={loading || areTokensLoading} logger={logger} />
                )
            )}
        </div>
    );
};
