import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "src/components";
import { MoreIcon } from "src/icons";
import { Routes } from "src/routes";
import { useCancelOrderMutation } from "src/serverApi";
import { type DocumentId, OrderStateEnum } from "src/types";
import { logger } from "../logger";

type OrderMenuProps = Readonly<{
    documentId: DocumentId;
    state: OrderStateEnum;
}>;

export const OrderMenu: FC<OrderMenuProps> = ({ documentId, state }) => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [cancelOrder] = useCancelOrderMutation();

    // TODO: error and success handling
    const handleCancelOrder = async () => {
        try {
            await cancelOrder(documentId).unwrap();
        } catch (error) {
            logger.error("Failed to cancel order", error);
        }
    };

    return (
        <DropdownMenu.Root onOpenChange={() => setIsMenuOpen(!isMenuOpen)}>
            <DropdownMenu.Trigger asChild>
                <button type="button" title="Toggle menu" aria-label="Toggle menu" className="fill-secondary hover:fill-brand-link">
                    <MoreIcon className="size-6" />
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    align="end"
                    className="flex flex-col gap-1 rounded-lg border border-primary bg-white px-4 py-2 shadow-e300 focus:outline-none"
                >
                    <DropdownMenu.Item>
                        <Link
                            className="text-sm text-secondary hover:text-link"
                            href={Routes.orderDetail.fillPathParams({ orderDocumentId: documentId })}
                        >
                            {t("common.view")}
                        </Link>
                    </DropdownMenu.Item>
                    {[OrderStateEnum.Open, OrderStateEnum.Unpaid].includes(state) && (
                        <>
                            <DropdownMenu.Item>
                                <Link
                                    className="text-sm text-secondary hover:text-link"
                                    href={Routes.orderPay.fillPathParams({ orderDocumentId: documentId })}
                                >
                                    {t("common.pay")}
                                </Link>
                            </DropdownMenu.Item>
                            <DropdownMenu.Separator className="my-1 h-px w-full bg-secondary" />
                            <DropdownMenu.Item>
                                <button
                                    type="button"
                                    className="cursor-pointer text-sm text-error hover:text-error"
                                    onClick={handleCancelOrder}
                                >
                                    {t("common.cancel")}
                                </button>
                            </DropdownMenu.Item>
                        </>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};
