import { type FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Loading, PopupWindow } from "src/components";
import { Routes } from "src/routes";
import { useTranslation } from "src/translations";
import { UserVerificationTypeEnum } from "src/types";
import { useUserVerification } from "src/verification";

export type InvestButtonProps = Readonly<{
    projectSlug: string;
    onlyForCurrentInvestor?: boolean;
    isFloating?: boolean;
}>;

type UserStepValue = {
    label: string;
    path: string;
    popupWindow?: {
        title: string;
        text: string;
        confirmButtonText: string;
    };
};

export const InvestButton: FC<InvestButtonProps> = ({ projectSlug, onlyForCurrentInvestor, isFloating }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isVerificationLoading, currentVerification, nextVerification, isVerificationDone } = useUserVerification();

    const userStepData: Record<UserVerificationTypeEnum, UserStepValue> = useMemo(
        () => ({
            [UserVerificationTypeEnum.SIGNUP]: {
                label: t("projects.CTA.signUp.label"),
                path: Routes.signUp.path,
            },
            [UserVerificationTypeEnum.KYC]: {
                label: t("projects.CTA.invest.label"),
                path: `${Routes.verifications.fillPathParams({ verificationType: UserVerificationTypeEnum.KYC })}?slug=${projectSlug}`,
                popupWindow: {
                    title: t("projects.CTA.failure.KYC.title"),
                    text: t("projects.CTA.failure.KYC.text"),
                    confirmButtonText: t("projects.CTA.failure.KYC.buttonText"),
                },
            },
            [UserVerificationTypeEnum.AML]: {
                label: t("projects.CTA.invest.label"),
                path: `${Routes.verifications.fillPathParams({ verificationType: UserVerificationTypeEnum.AML })}?slug=${projectSlug}`,
                popupWindow: {
                    title: t("projects.CTA.failure.AML.title"),
                    text: t("projects.CTA.failure.AML.text"),
                    confirmButtonText: t("projects.CTA.failure.AML.buttonText"),
                },
            },
            [UserVerificationTypeEnum.ORDER]: {
                label: t("projects.CTA.invest.label"),
                path: Routes.projectOrder.fillPathParams({ projectSlug }),
            },
            [UserVerificationTypeEnum.INVESTOR]: {
                label: t("projects.CTA.invest.label"),
                path: Routes.projectOrder.fillPathParams({ projectSlug }),
            },
        }),
        [t, projectSlug],
    );

    const { label, path, popupWindow } = userStepData[nextVerification.type];
    const isUserInvestor = isVerificationDone(UserVerificationTypeEnum.INVESTOR);

    const handleClick = async () => {
        if (currentVerification.type === UserVerificationTypeEnum.ORDER && onlyForCurrentInvestor && !isUserInvestor) {
            await PopupWindow.fire({
                title: t("projects.order.failure.investorNeeded.title"),
                text: t("projects.order.failure.investorNeeded.text"),
                confirmButtonText: t("projects.order.failure.investorNeeded.buttonText"),
                timer: 1500,
            });
            return;
        }
        if (popupWindow) {
            await PopupWindow.fire({ ...popupWindow, timer: 5000 });
        }
        navigate(path);
    };

    return (
        <Button disabled={isVerificationLoading} fullWidth size={isFloating ? "large" : "default"} onClick={handleClick}>
            {isVerificationLoading ? <Loading /> : label}
        </Button>
    );
};
