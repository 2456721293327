// TODO: locale does not seem to be used, get it from current i18n lang? @see toLocalDate
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ProjectInfoStateEnum } from "src/types";

export const useScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
};

export const isProjectInInvestState = (state: ProjectInfoStateEnum) =>
    state !== ProjectInfoStateEnum.Unknown && state !== ProjectInfoStateEnum.Closed && state !== ProjectInfoStateEnum.Prepared;

export const getEmbeddedVideoUrl = (provider: string, providerUid: string, url: string) => {
    switch (provider) {
        case "youtube":
            return `https://www.youtube.com/embed/${providerUid}`;
        case "vimeo":
            return `https://player.vimeo.com/video/${providerUid}`;
        default:
            return url;
    }
};
