import type {
    Currency,
    DocumentId,
    GetProjectsParams,
    ProjectInfo,
    ProjectInfoListResponse,
    ProjectInfoResponse,
    ProjectStats,
    UserPortfolio,
} from "src/types";
import { rootApi } from "./rootApi";
import { ENTITY_WITH_TRANSLATIONS_TAG, PROJECT_TAG, USER_PORTFOLIO_TAG } from "./tags";
import { getCmsUrl } from "./utils";

export const projectApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        projectsInfo: build.query<ProjectInfoListResponse, GetProjectsParams>({
            query: (params) => ({
                url: getCmsUrl("/api/project-infos"),
                params: {
                    populate: {
                        project: {
                            populate: {
                                calculation: true,
                                images: true,
                            },
                        },
                    },
                    ...params,
                },
            }),
            keepUnusedDataFor: 10,
            providesTags: [PROJECT_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        projectInfoDetailByDocumentId: build.query<ProjectInfo, string>({
            query: (documentId) => ({
                url: getCmsUrl(`/api/project-infos/${documentId}`),
                params: {
                    status: "published",
                    populate: {
                        project: {
                            populate: {
                                images: true,
                                layout: true,
                                calculation: true,
                                tokenCurrentNominalPrice: {
                                    sort: "appliedAt:desc",
                                },
                            },
                        },
                        description: true,
                        informationProspect: true,
                        documents: true,
                    },
                },
                keepUnusedDataFor: 10,
            }),
            transformResponse: (response: ProjectInfoResponse) => {
                const projectsInfo = response?.data;
                if (!projectsInfo) {
                    throw new Error("Project info not found");
                }
                return projectsInfo;
            },
            providesTags: (result) => [{ type: PROJECT_TAG, id: result?.id }, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        userProjectPortfolio: build.query<UserPortfolio, Currency>({
            query: (currency) => ({
                url: getCmsUrl("/api/projects/portfolio"),
                params: { currency },
            }),
            providesTags: [USER_PORTFOLIO_TAG, ENTITY_WITH_TRANSLATIONS_TAG],
        }),
        projectDetailStats: build.query<ProjectStats, DocumentId>({
            query: (documentId) => ({
                url: getCmsUrl("/api/projects/detail-stats"),
                params: { documentId },
            }),
            providesTags: [ENTITY_WITH_TRANSLATIONS_TAG],
        }),
    }),
});

export const { useProjectsInfoQuery, useProjectInfoDetailByDocumentIdQuery, useUserProjectPortfolioQuery, useProjectDetailStatsQuery } =
    projectApi;
