/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Error {
    data?: object | object[] | null;
    error: {
        status?: number;
        name?: string;
        message?: string;
        details?: object;
    };
}

export interface AppConfigurationRequest {
    data: {
        title: string;
        /** @example "string or id" */
        image: number | string;
        /** @example "string or id" */
        termsAndConditions: number | string;
        /** @example "string or id" */
        privacyPolicy: number | string;
        /** @example "string or id" */
        whitePaper: number | string;
        /** @format float */
        conversionRateEurToCzk: number;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface AppConfigurationListResponse {
    data?: AppConfiguration[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface AppConfiguration {
    id?: number;
    documentId?: string;
    title: string;
    image: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
            name?: string;
            pathId?: number;
            parent?: {
                id?: number;
                documentId?: string;
            };
            children?: {
                id?: number;
                documentId?: string;
            }[];
            files?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        code?: string;
                        description?: string;
                        users?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        permissions?: {
                            id?: number;
                            documentId?: string;
                            action?: string;
                            actionParameters?: any;
                            subject?: string;
                            properties?: any;
                            conditions?: any;
                            role?: {
                                id?: number;
                                documentId?: string;
                            };
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            path?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    termsAndConditions: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    privacyPolicy: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    whitePaper: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    /** @format float */
    conversionRateEurToCzk: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
        title?: string;
        image?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        termsAndConditions?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        privacyPolicy?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        whitePaper?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format float */
        conversionRateEurToCzk?: number;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
}

export interface AppConfigurationResponse {
    data?: AppConfiguration;
    meta?: object;
}

export interface ArticleRequest {
    data: {
        title: string;
        author: string;
        tags?: (number | string)[];
        /** @example "string or id" */
        image?: number | string;
        type: ArticleRequestTypeEnum;
        permissions?: ArticlePermissionsComponent;
        slug: string;
        description?: any;
        content: any;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface ArticleListResponse {
    data?: Article[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Article {
    id?: number;
    documentId?: string;
    title: string;
    author: string;
    tags?: {
        id?: number;
        documentId?: string;
        name?: string;
        slug?: string;
        articles?: {
            id?: number;
            documentId?: string;
            title?: string;
            author?: string;
            tags?: {
                id?: number;
                documentId?: string;
            }[];
            image?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    pathId?: number;
                    parent?: {
                        id?: number;
                        documentId?: string;
                    };
                    children?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    files?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        alternativeText?: string;
                        caption?: string;
                        width?: number;
                        height?: number;
                        formats?: any;
                        hash?: string;
                        ext?: string;
                        mime?: string;
                        /** @format float */
                        size?: number;
                        url?: string;
                        previewUrl?: string;
                        provider?: string;
                        provider_metadata?: any;
                        related?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        folder?: {
                            id?: number;
                            documentId?: string;
                        };
                        folderPath?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                            firstname?: string;
                            lastname?: string;
                            username?: string;
                            /** @format email */
                            email?: string;
                            resetPasswordToken?: string;
                            registrationToken?: string;
                            isActive?: boolean;
                            roles?: {
                                id?: number;
                                documentId?: string;
                                name?: string;
                                code?: string;
                                description?: string;
                                users?: {
                                    id?: number;
                                    documentId?: string;
                                }[];
                                permissions?: {
                                    id?: number;
                                    documentId?: string;
                                    action?: string;
                                    actionParameters?: any;
                                    subject?: string;
                                    properties?: any;
                                    conditions?: any;
                                    role?: {
                                        id?: number;
                                        documentId?: string;
                                    };
                                    /** @format date-time */
                                    createdAt?: string;
                                    /** @format date-time */
                                    updatedAt?: string;
                                    /** @format date-time */
                                    publishedAt?: string;
                                    createdBy?: {
                                        id?: number;
                                        documentId?: string;
                                    };
                                    updatedBy?: {
                                        id?: number;
                                        documentId?: string;
                                    };
                                    locale?: string;
                                    localizations?: {
                                        id?: number;
                                        documentId?: string;
                                    }[];
                                }[];
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                /** @format date-time */
                                publishedAt?: string;
                                createdBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                updatedBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                locale?: string;
                                localizations?: {
                                    id?: number;
                                    documentId?: string;
                                }[];
                            }[];
                            blocked?: boolean;
                            preferedLanguage?: string;
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    path?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            type?: ArticleTypeEnum;
            permissions?: ArticlePermissionsComponent;
            slug?: string;
            description?: any;
            content?: any;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
    image?: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    type: ArticleTypeEnum1;
    permissions?: ArticlePermissionsComponent;
    slug: string;
    description?: any;
    content: any;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface ArticleResponse {
    data?: Article;
    meta?: object;
}

export interface ArticlePermissionsComponent {
    id?: number;
    visibleForUsers?: {
        id?: number;
        documentId?: string;
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
            id?: number;
            documentId?: string;
            name?: string;
            description?: string;
            type?: string;
            permissions?: {
                id?: number;
                documentId?: string;
                action?: string;
                role?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            users?: {
                id?: number;
                documentId?: string;
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        verification?: {
            id?: number;
            documentId?: string;
            relevance?: number;
            type?: ArticlePermissionsComponentTypeEnum;
            timeToComplete?: number;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        canceled?: boolean;
        userProfile?: {
            id?: number;
            documentId?: string;
            firstName?: string;
            lastName?: string;
            bankAccounts?: any;
            phone?: string;
            residentialAddress?: string;
            cityAddress?: string;
            postalCode?: string;
            countryAddress?: string;
            socialSecurityNumber?: string;
            idOrPassportNumber?: string;
            countryOfOrigin?: string;
            citizenship?: string;
            /** @format date */
            documentValidUntil?: string;
            purposeOfContract?: string;
            expectedTradesVolume?: string;
            alimonyBenefitsPension?: boolean;
            salary?: boolean;
            business?: boolean;
            capital?: boolean;
            other?: string;
            noCriminalActivity?: boolean;
            countriesWithSanctions?: boolean;
            politicallyExposed?: boolean;
            sourceOfWealthSalary?: boolean;
            sourceOfWealthCapital?: boolean;
            sourceOfWealthBusiness?: boolean;
            sourceOfWealthInheritance?: boolean;
            sourceOfWealthOther?: string;
            user?: {
                id?: number;
                documentId?: string;
            };
            documentIssuedBy?: string;
            /** @format date */
            dateOfBirth?: string;
            /** @format date-time */
            lastLogin?: string;
            language?: ArticlePermissionsComponentLanguageEnum;
            currency?: ArticlePermissionsComponentCurrencyEnum;
            hsCookieId?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
    hiddenForProjects?: {
        id?: number;
        documentId?: string;
        images?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        layout?: ProjectLayoutComponent;
        /** @format date */
        completedOn?: string;
        /** @format date */
        closedOn?: string;
        /** @format date */
        expectedOn?: string;
        calculation?: ProjectCalculationComponent;
        tokenTotalCount?: number;
        minPurchaseQty?: number;
        maxPurchaseQty?: number;
        latitude?: string;
        longitude?: string;
        countryCode?: string;
        onlyForCurrentInvestor?: boolean;
        currency?: ArticlePermissionsComponentCurrencyEnum1;
        tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
        state?: ArticlePermissionsComponentStateEnum;
        projectInfo?: {
            id?: number;
            documentId?: string;
            title?: string;
            slug?: string;
            informationProspect?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            description?: ProjectDescriptionWithTitleComponent[];
            documents?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            project?: {
                id?: number;
                documentId?: string;
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        internalName?: string;
        /** @format float */
        tokenTotalPrice?: number;
        /** @format float */
        tokenFee?: number;
        tokenGenerationState?: ArticlePermissionsComponentTokenGenerationStateEnum;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
    type?: ArticlePermissionsComponentTypeEnum1;
}

export interface ProjectLayoutComponent {
    id?: number;
    floor?: number;
    bedrooms?: number;
    bathrooms?: number;
    /** @format float */
    size?: number;
    energyClass?: ProjectLayoutComponentEnergyClassEnum;
    material?: ProjectLayoutComponentMaterialEnum;
}

export interface ProjectCalculationComponent {
    id?: number;
    /** @format float */
    paReturn?: number;
    period?: number;
    /** @format float */
    incrementalPropertyValue?: number;
    /** @format float */
    annualRentalIncome?: number;
    /** @format float */
    calculatorMin?: number;
    /** @format float */
    calculatorMax?: number;
}

export interface ProjectCurrentPriceComponent {
    id?: number;
    /** @format date */
    appliedAt?: string;
    /** @format float */
    price?: number;
}

export interface ProjectDescriptionWithTitleComponent {
    id?: number;
    title?: string;
    description?: any;
    video?: any;
}

export interface BannerRequest {
    data: {
        page: BannerRequestPageEnum;
        condition: BannerRequestConditionEnum;
        /** @example "string or id" */
        large: number | string;
        /** @example "string or id" */
        medium: number | string;
        /** @example "string or id" */
        small: number | string;
        link?: string;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface BannerListResponse {
    data?: Banner[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Banner {
    id?: number;
    documentId?: string;
    page: BannerPageEnum;
    condition: BannerConditionEnum;
    large: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
            name?: string;
            pathId?: number;
            parent?: {
                id?: number;
                documentId?: string;
            };
            children?: {
                id?: number;
                documentId?: string;
            }[];
            files?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        code?: string;
                        description?: string;
                        users?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        permissions?: {
                            id?: number;
                            documentId?: string;
                            action?: string;
                            actionParameters?: any;
                            subject?: string;
                            properties?: any;
                            conditions?: any;
                            role?: {
                                id?: number;
                                documentId?: string;
                            };
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            path?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    medium: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    small: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    link?: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
        page?: BannerPageEnum1;
        condition?: BannerConditionEnum1;
        large?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        medium?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        small?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        link?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
}

export interface BannerResponse {
    data?: Banner;
    meta?: object;
}

export interface FaqRequest {
    data: {
        title: string;
        content: any;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface FaqListResponse {
    data?: Faq[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Faq {
    id?: number;
    documentId?: string;
    title: string;
    content: any;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
            id?: number;
            documentId?: string;
            name?: string;
            code?: string;
            description?: string;
            users?: {
                id?: number;
                documentId?: string;
            }[];
            permissions?: {
                id?: number;
                documentId?: string;
                action?: string;
                actionParameters?: any;
                subject?: string;
                properties?: any;
                conditions?: any;
                role?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
        title?: string;
        content?: any;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
}

export interface FaqResponse {
    data?: Faq;
    meta?: object;
}

export interface FundedRequestRequest {
    data: {
        /** @example "string or id" */
        project?: number | string;
        /** @example "string or id" */
        user?: number | string;
        processed?: boolean;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface FundedRequestListResponse {
    data?: FundedRequest[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface FundedRequest {
    id?: number;
    documentId?: string;
    project?: {
        id?: number;
        documentId?: string;
        images?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
                name?: string;
                pathId?: number;
                parent?: {
                    id?: number;
                    documentId?: string;
                };
                children?: {
                    id?: number;
                    documentId?: string;
                }[];
                files?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    alternativeText?: string;
                    caption?: string;
                    width?: number;
                    height?: number;
                    formats?: any;
                    hash?: string;
                    ext?: string;
                    mime?: string;
                    /** @format float */
                    size?: number;
                    url?: string;
                    previewUrl?: string;
                    provider?: string;
                    provider_metadata?: any;
                    related?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    folder?: {
                        id?: number;
                        documentId?: string;
                    };
                    folderPath?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                        firstname?: string;
                        lastname?: string;
                        username?: string;
                        /** @format email */
                        email?: string;
                        resetPasswordToken?: string;
                        registrationToken?: string;
                        isActive?: boolean;
                        roles?: {
                            id?: number;
                            documentId?: string;
                            name?: string;
                            code?: string;
                            description?: string;
                            users?: {
                                id?: number;
                                documentId?: string;
                            }[];
                            permissions?: {
                                id?: number;
                                documentId?: string;
                                action?: string;
                                actionParameters?: any;
                                subject?: string;
                                properties?: any;
                                conditions?: any;
                                role?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                /** @format date-time */
                                publishedAt?: string;
                                createdBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                updatedBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                locale?: string;
                                localizations?: {
                                    id?: number;
                                    documentId?: string;
                                }[];
                            }[];
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        blocked?: boolean;
                        preferedLanguage?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                path?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        layout?: ProjectLayoutComponent;
        /** @format date */
        completedOn?: string;
        /** @format date */
        closedOn?: string;
        /** @format date */
        expectedOn?: string;
        calculation?: ProjectCalculationComponent;
        tokenTotalCount?: number;
        minPurchaseQty?: number;
        maxPurchaseQty?: number;
        latitude?: string;
        longitude?: string;
        countryCode?: string;
        onlyForCurrentInvestor?: boolean;
        currency?: FundedRequestCurrencyEnum;
        tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
        state?: FundedRequestStateEnum;
        projectInfo?: {
            id?: number;
            documentId?: string;
            title?: string;
            slug?: string;
            informationProspect?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            description?: ProjectDescriptionWithTitleComponent[];
            documents?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            project?: {
                id?: number;
                documentId?: string;
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        internalName?: string;
        /** @format float */
        tokenTotalPrice?: number;
        /** @format float */
        tokenFee?: number;
        tokenGenerationState?: FundedRequestTokenGenerationStateEnum;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    user?: {
        id?: number;
        documentId?: string;
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
            id?: number;
            documentId?: string;
            name?: string;
            description?: string;
            type?: string;
            permissions?: {
                id?: number;
                documentId?: string;
                action?: string;
                role?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            users?: {
                id?: number;
                documentId?: string;
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        verification?: {
            id?: number;
            documentId?: string;
            relevance?: number;
            type?: FundedRequestTypeEnum;
            timeToComplete?: number;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        canceled?: boolean;
        userProfile?: {
            id?: number;
            documentId?: string;
            firstName?: string;
            lastName?: string;
            bankAccounts?: any;
            phone?: string;
            residentialAddress?: string;
            cityAddress?: string;
            postalCode?: string;
            countryAddress?: string;
            socialSecurityNumber?: string;
            idOrPassportNumber?: string;
            countryOfOrigin?: string;
            citizenship?: string;
            /** @format date */
            documentValidUntil?: string;
            purposeOfContract?: string;
            expectedTradesVolume?: string;
            alimonyBenefitsPension?: boolean;
            salary?: boolean;
            business?: boolean;
            capital?: boolean;
            other?: string;
            noCriminalActivity?: boolean;
            countriesWithSanctions?: boolean;
            politicallyExposed?: boolean;
            sourceOfWealthSalary?: boolean;
            sourceOfWealthCapital?: boolean;
            sourceOfWealthBusiness?: boolean;
            sourceOfWealthInheritance?: boolean;
            sourceOfWealthOther?: string;
            user?: {
                id?: number;
                documentId?: string;
            };
            documentIssuedBy?: string;
            /** @format date */
            dateOfBirth?: string;
            /** @format date-time */
            lastLogin?: string;
            language?: FundedRequestLanguageEnum;
            currency?: FundedRequestCurrencyEnum1;
            hsCookieId?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    processed?: boolean;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
        project?: {
            id?: number;
            documentId?: string;
        };
        user?: {
            id?: number;
            documentId?: string;
        };
        processed?: boolean;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
}

export interface FundedRequestResponse {
    data?: FundedRequest;
    meta?: object;
}

export interface MessageRequest {
    data: {
        relevance: number;
        type: MessageRequestTypeEnum;
        text: any;
        visibleForDaysFromRegistration?: number;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface MessageListResponse {
    data?: Message[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Message {
    id?: number;
    documentId?: string;
    relevance: number;
    type: MessageTypeEnum;
    text: any;
    visibleForDaysFromRegistration?: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
            id?: number;
            documentId?: string;
            name?: string;
            code?: string;
            description?: string;
            users?: {
                id?: number;
                documentId?: string;
            }[];
            permissions?: {
                id?: number;
                documentId?: string;
                action?: string;
                actionParameters?: any;
                subject?: string;
                properties?: any;
                conditions?: any;
                role?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
        relevance?: number;
        type?: MessageTypeEnum1;
        text?: any;
        visibleForDaysFromRegistration?: number;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
}

export interface MessageResponse {
    data?: Message;
    meta?: object;
}

export interface OrderRequest {
    data: {
        state: OrderRequestStateEnum;
        /** @example "string or id" */
        project?: number | string;
        /** @example "string or id" */
        user?: number | string;
        tokens?: (number | string)[];
        paymentType?: OrderRequestPaymentTypeEnum;
        /** @format float */
        tokenCount: number;
        currency: OrderRequestCurrencyEnum;
        /**
         * @pattern ^\d*$
         * @example "123456789"
         */
        bankTransferReferenceCode?: string;
        /** @format float */
        price?: number;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface OrderListResponse {
    data?: Order[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Order {
    id?: number;
    documentId?: string;
    state: OrderStateEnum;
    project?: {
        id?: number;
        documentId?: string;
        images?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
                name?: string;
                pathId?: number;
                parent?: {
                    id?: number;
                    documentId?: string;
                };
                children?: {
                    id?: number;
                    documentId?: string;
                }[];
                files?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    alternativeText?: string;
                    caption?: string;
                    width?: number;
                    height?: number;
                    formats?: any;
                    hash?: string;
                    ext?: string;
                    mime?: string;
                    /** @format float */
                    size?: number;
                    url?: string;
                    previewUrl?: string;
                    provider?: string;
                    provider_metadata?: any;
                    related?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    folder?: {
                        id?: number;
                        documentId?: string;
                    };
                    folderPath?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                        firstname?: string;
                        lastname?: string;
                        username?: string;
                        /** @format email */
                        email?: string;
                        resetPasswordToken?: string;
                        registrationToken?: string;
                        isActive?: boolean;
                        roles?: {
                            id?: number;
                            documentId?: string;
                            name?: string;
                            code?: string;
                            description?: string;
                            users?: {
                                id?: number;
                                documentId?: string;
                            }[];
                            permissions?: {
                                id?: number;
                                documentId?: string;
                                action?: string;
                                actionParameters?: any;
                                subject?: string;
                                properties?: any;
                                conditions?: any;
                                role?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                /** @format date-time */
                                publishedAt?: string;
                                createdBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                updatedBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                locale?: string;
                                localizations?: {
                                    id?: number;
                                    documentId?: string;
                                }[];
                            }[];
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        blocked?: boolean;
                        preferedLanguage?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                path?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        layout?: ProjectLayoutComponent;
        /** @format date */
        completedOn?: string;
        /** @format date */
        closedOn?: string;
        /** @format date */
        expectedOn?: string;
        calculation?: ProjectCalculationComponent;
        tokenTotalCount?: number;
        minPurchaseQty?: number;
        maxPurchaseQty?: number;
        latitude?: string;
        longitude?: string;
        countryCode?: string;
        onlyForCurrentInvestor?: boolean;
        currency?: OrderCurrencyEnum;
        tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
        state?: OrderStateEnum1;
        projectInfo?: {
            id?: number;
            documentId?: string;
            title?: string;
            slug?: string;
            informationProspect?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            description?: ProjectDescriptionWithTitleComponent[];
            documents?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            project?: {
                id?: number;
                documentId?: string;
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        internalName?: string;
        /** @format float */
        tokenTotalPrice?: number;
        /** @format float */
        tokenFee?: number;
        tokenGenerationState?: OrderTokenGenerationStateEnum;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    user?: {
        id?: number;
        documentId?: string;
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
            id?: number;
            documentId?: string;
            name?: string;
            description?: string;
            type?: string;
            permissions?: {
                id?: number;
                documentId?: string;
                action?: string;
                role?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            users?: {
                id?: number;
                documentId?: string;
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        verification?: {
            id?: number;
            documentId?: string;
            relevance?: number;
            type?: OrderTypeEnum;
            timeToComplete?: number;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        canceled?: boolean;
        userProfile?: {
            id?: number;
            documentId?: string;
            firstName?: string;
            lastName?: string;
            bankAccounts?: any;
            phone?: string;
            residentialAddress?: string;
            cityAddress?: string;
            postalCode?: string;
            countryAddress?: string;
            socialSecurityNumber?: string;
            idOrPassportNumber?: string;
            countryOfOrigin?: string;
            citizenship?: string;
            /** @format date */
            documentValidUntil?: string;
            purposeOfContract?: string;
            expectedTradesVolume?: string;
            alimonyBenefitsPension?: boolean;
            salary?: boolean;
            business?: boolean;
            capital?: boolean;
            other?: string;
            noCriminalActivity?: boolean;
            countriesWithSanctions?: boolean;
            politicallyExposed?: boolean;
            sourceOfWealthSalary?: boolean;
            sourceOfWealthCapital?: boolean;
            sourceOfWealthBusiness?: boolean;
            sourceOfWealthInheritance?: boolean;
            sourceOfWealthOther?: string;
            user?: {
                id?: number;
                documentId?: string;
            };
            documentIssuedBy?: string;
            /** @format date */
            dateOfBirth?: string;
            /** @format date-time */
            lastLogin?: string;
            language?: OrderLanguageEnum;
            currency?: OrderCurrencyEnum1;
            hsCookieId?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    tokens?: {
        id?: number;
        documentId?: string;
        sequence?: number;
        sold?: boolean;
        project?: {
            id?: number;
            documentId?: string;
        };
        uid?: string;
        order?: {
            id?: number;
            documentId?: string;
            state?: OrderStateEnum2;
            project?: {
                id?: number;
                documentId?: string;
            };
            user?: {
                id?: number;
                documentId?: string;
            };
            tokens?: {
                id?: number;
                documentId?: string;
            }[];
            paymentType?: OrderPaymentTypeEnum;
            /** @format float */
            tokenCount?: number;
            currency?: OrderCurrencyEnum2;
            /**
             * @pattern ^\d*$
             * @example "123456789"
             */
            bankTransferReferenceCode?: string;
            /** @format float */
            price?: number;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
    paymentType?: OrderPaymentTypeEnum1;
    /** @format float */
    tokenCount: number;
    currency: OrderCurrencyEnum3;
    /**
     * @pattern ^\d*$
     * @example "123456789"
     */
    bankTransferReferenceCode?: string;
    /** @format float */
    price?: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface OrderResponse {
    data?: Order;
    meta?: object;
}

export interface ProjectRequest {
    data: {
        images?: (number | string)[];
        layout?: ProjectLayoutComponent;
        /** @format date */
        completedOn?: string;
        /** @format date */
        closedOn?: string;
        /** @format date */
        expectedOn?: string;
        calculation?: ProjectCalculationComponent;
        tokenTotalCount: number;
        minPurchaseQty: number;
        maxPurchaseQty: number;
        latitude: string;
        longitude: string;
        countryCode: string;
        onlyForCurrentInvestor: boolean;
        currency: ProjectRequestCurrencyEnum;
        tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
        state?: ProjectRequestStateEnum;
        /** @example "string or id" */
        projectInfo?: number | string;
        internalName: string;
        /** @format float */
        tokenTotalPrice?: number;
        /** @format float */
        tokenFee?: number;
        tokenGenerationState: ProjectRequestTokenGenerationStateEnum;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface ProjectListResponse {
    data?: Project[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Project {
    id?: number;
    documentId?: string;
    images?: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
            name?: string;
            pathId?: number;
            parent?: {
                id?: number;
                documentId?: string;
            };
            children?: {
                id?: number;
                documentId?: string;
            }[];
            files?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        code?: string;
                        description?: string;
                        users?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        permissions?: {
                            id?: number;
                            documentId?: string;
                            action?: string;
                            actionParameters?: any;
                            subject?: string;
                            properties?: any;
                            conditions?: any;
                            role?: {
                                id?: number;
                                documentId?: string;
                            };
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            path?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
    layout?: ProjectLayoutComponent;
    /** @format date */
    completedOn?: string;
    /** @format date */
    closedOn?: string;
    /** @format date */
    expectedOn?: string;
    calculation?: ProjectCalculationComponent;
    tokenTotalCount: number;
    minPurchaseQty: number;
    maxPurchaseQty: number;
    latitude: string;
    longitude: string;
    countryCode: string;
    onlyForCurrentInvestor: boolean;
    currency: ProjectCurrencyEnum;
    tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
    state?: ProjectStateEnum;
    projectInfo?: {
        id?: number;
        documentId?: string;
        title?: string;
        slug?: string;
        informationProspect?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        description?: ProjectDescriptionWithTitleComponent[];
        documents?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        project?: {
            id?: number;
            documentId?: string;
            images?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            layout?: ProjectLayoutComponent;
            /** @format date */
            completedOn?: string;
            /** @format date */
            closedOn?: string;
            /** @format date */
            expectedOn?: string;
            calculation?: ProjectCalculationComponent;
            tokenTotalCount?: number;
            minPurchaseQty?: number;
            maxPurchaseQty?: number;
            latitude?: string;
            longitude?: string;
            countryCode?: string;
            onlyForCurrentInvestor?: boolean;
            currency?: ProjectCurrencyEnum1;
            tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
            state?: ProjectStateEnum1;
            projectInfo?: {
                id?: number;
                documentId?: string;
            };
            internalName?: string;
            /** @format float */
            tokenTotalPrice?: number;
            /** @format float */
            tokenFee?: number;
            tokenGenerationState?: ProjectTokenGenerationStateEnum;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    internalName: string;
    /** @format float */
    tokenTotalPrice?: number;
    /** @format float */
    tokenFee?: number;
    tokenGenerationState: ProjectTokenGenerationStateEnum1;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface ProjectResponse {
    data?: Project;
    meta?: object;
}

export interface ProjectInfoRequest {
    data: {
        title: string;
        slug: string;
        /** @example "string or id" */
        informationProspect?: number | string;
        description?: ProjectDescriptionWithTitleComponent[];
        documents?: (number | string)[];
        /** @example "string or id" */
        project?: number | string;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface ProjectInfoListResponse {
    data?: ProjectInfo[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface ProjectInfo {
    id?: number;
    documentId?: string;
    title: string;
    slug: string;
    informationProspect?: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
            name?: string;
            pathId?: number;
            parent?: {
                id?: number;
                documentId?: string;
            };
            children?: {
                id?: number;
                documentId?: string;
            }[];
            files?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        code?: string;
                        description?: string;
                        users?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        permissions?: {
                            id?: number;
                            documentId?: string;
                            action?: string;
                            actionParameters?: any;
                            subject?: string;
                            properties?: any;
                            conditions?: any;
                            role?: {
                                id?: number;
                                documentId?: string;
                            };
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            path?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    description?: ProjectDescriptionWithTitleComponent[];
    documents?: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
    project?: {
        id?: number;
        documentId?: string;
        images?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        layout?: ProjectLayoutComponent;
        /** @format date */
        completedOn?: string;
        /** @format date */
        closedOn?: string;
        /** @format date */
        expectedOn?: string;
        calculation?: ProjectCalculationComponent;
        tokenTotalCount?: number;
        minPurchaseQty?: number;
        maxPurchaseQty?: number;
        latitude?: string;
        longitude?: string;
        countryCode?: string;
        onlyForCurrentInvestor?: boolean;
        currency?: ProjectInfoCurrencyEnum;
        tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
        state?: ProjectInfoStateEnum;
        projectInfo?: {
            id?: number;
            documentId?: string;
            title?: string;
            slug?: string;
            informationProspect?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            description?: ProjectDescriptionWithTitleComponent[];
            documents?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            project?: {
                id?: number;
                documentId?: string;
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        internalName?: string;
        /** @format float */
        tokenTotalPrice?: number;
        /** @format float */
        tokenFee?: number;
        tokenGenerationState?: ProjectInfoTokenGenerationStateEnum;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface ProjectInfoResponse {
    data?: ProjectInfo;
    meta?: object;
}

export interface TagRequest {
    data: {
        name: string;
        slug: string;
        articles?: (number | string)[];
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface TagListResponse {
    data?: Tag[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Tag {
    id?: number;
    documentId?: string;
    name: string;
    slug: string;
    articles?: {
        id?: number;
        documentId?: string;
        title?: string;
        author?: string;
        tags?: {
            id?: number;
            documentId?: string;
            name?: string;
            slug?: string;
            articles?: {
                id?: number;
                documentId?: string;
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
                firstname?: string;
                lastname?: string;
                username?: string;
                /** @format email */
                email?: string;
                resetPasswordToken?: string;
                registrationToken?: string;
                isActive?: boolean;
                roles?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    code?: string;
                    description?: string;
                    users?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    permissions?: {
                        id?: number;
                        documentId?: string;
                        action?: string;
                        actionParameters?: any;
                        subject?: string;
                        properties?: any;
                        conditions?: any;
                        role?: {
                            id?: number;
                            documentId?: string;
                        };
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                blocked?: boolean;
                preferedLanguage?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        image?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
                name?: string;
                pathId?: number;
                parent?: {
                    id?: number;
                    documentId?: string;
                };
                children?: {
                    id?: number;
                    documentId?: string;
                }[];
                files?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    alternativeText?: string;
                    caption?: string;
                    width?: number;
                    height?: number;
                    formats?: any;
                    hash?: string;
                    ext?: string;
                    mime?: string;
                    /** @format float */
                    size?: number;
                    url?: string;
                    previewUrl?: string;
                    provider?: string;
                    provider_metadata?: any;
                    related?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    folder?: {
                        id?: number;
                        documentId?: string;
                    };
                    folderPath?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                path?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        type?: TagTypeEnum;
        permissions?: ArticlePermissionsComponent;
        slug?: string;
        description?: any;
        content?: any;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface TagResponse {
    data?: Tag;
    meta?: object;
}

export interface TokenRequest {
    data: {
        sequence: number;
        sold: boolean;
        /** @example "string or id" */
        project?: number | string;
        uid: string;
        /** @example "string or id" */
        order?: number | string;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface TokenListResponse {
    data?: Token[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Token {
    id?: number;
    documentId?: string;
    sequence: number;
    sold: boolean;
    project?: {
        id?: number;
        documentId?: string;
        images?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
                name?: string;
                pathId?: number;
                parent?: {
                    id?: number;
                    documentId?: string;
                };
                children?: {
                    id?: number;
                    documentId?: string;
                }[];
                files?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    alternativeText?: string;
                    caption?: string;
                    width?: number;
                    height?: number;
                    formats?: any;
                    hash?: string;
                    ext?: string;
                    mime?: string;
                    /** @format float */
                    size?: number;
                    url?: string;
                    previewUrl?: string;
                    provider?: string;
                    provider_metadata?: any;
                    related?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    folder?: {
                        id?: number;
                        documentId?: string;
                    };
                    folderPath?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                        firstname?: string;
                        lastname?: string;
                        username?: string;
                        /** @format email */
                        email?: string;
                        resetPasswordToken?: string;
                        registrationToken?: string;
                        isActive?: boolean;
                        roles?: {
                            id?: number;
                            documentId?: string;
                            name?: string;
                            code?: string;
                            description?: string;
                            users?: {
                                id?: number;
                                documentId?: string;
                            }[];
                            permissions?: {
                                id?: number;
                                documentId?: string;
                                action?: string;
                                actionParameters?: any;
                                subject?: string;
                                properties?: any;
                                conditions?: any;
                                role?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                /** @format date-time */
                                publishedAt?: string;
                                createdBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                updatedBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                locale?: string;
                                localizations?: {
                                    id?: number;
                                    documentId?: string;
                                }[];
                            }[];
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        blocked?: boolean;
                        preferedLanguage?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                path?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        layout?: ProjectLayoutComponent;
        /** @format date */
        completedOn?: string;
        /** @format date */
        closedOn?: string;
        /** @format date */
        expectedOn?: string;
        calculation?: ProjectCalculationComponent;
        tokenTotalCount?: number;
        minPurchaseQty?: number;
        maxPurchaseQty?: number;
        latitude?: string;
        longitude?: string;
        countryCode?: string;
        onlyForCurrentInvestor?: boolean;
        currency?: TokenCurrencyEnum;
        tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
        state?: TokenStateEnum;
        projectInfo?: {
            id?: number;
            documentId?: string;
            title?: string;
            slug?: string;
            informationProspect?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            description?: ProjectDescriptionWithTitleComponent[];
            documents?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            project?: {
                id?: number;
                documentId?: string;
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        internalName?: string;
        /** @format float */
        tokenTotalPrice?: number;
        /** @format float */
        tokenFee?: number;
        tokenGenerationState?: TokenTokenGenerationStateEnum;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    uid: string;
    order?: {
        id?: number;
        documentId?: string;
        state?: TokenStateEnum1;
        project?: {
            id?: number;
            documentId?: string;
        };
        user?: {
            id?: number;
            documentId?: string;
            username?: string;
            /** @format email */
            email?: string;
            provider?: string;
            resetPasswordToken?: string;
            confirmationToken?: string;
            confirmed?: boolean;
            blocked?: boolean;
            role?: {
                id?: number;
                documentId?: string;
                name?: string;
                description?: string;
                type?: string;
                permissions?: {
                    id?: number;
                    documentId?: string;
                    action?: string;
                    role?: {
                        id?: number;
                        documentId?: string;
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                users?: {
                    id?: number;
                    documentId?: string;
                }[];
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            verification?: {
                id?: number;
                documentId?: string;
                relevance?: number;
                type?: TokenTypeEnum;
                timeToComplete?: number;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            canceled?: boolean;
            userProfile?: {
                id?: number;
                documentId?: string;
                firstName?: string;
                lastName?: string;
                bankAccounts?: any;
                phone?: string;
                residentialAddress?: string;
                cityAddress?: string;
                postalCode?: string;
                countryAddress?: string;
                socialSecurityNumber?: string;
                idOrPassportNumber?: string;
                countryOfOrigin?: string;
                citizenship?: string;
                /** @format date */
                documentValidUntil?: string;
                purposeOfContract?: string;
                expectedTradesVolume?: string;
                alimonyBenefitsPension?: boolean;
                salary?: boolean;
                business?: boolean;
                capital?: boolean;
                other?: string;
                noCriminalActivity?: boolean;
                countriesWithSanctions?: boolean;
                politicallyExposed?: boolean;
                sourceOfWealthSalary?: boolean;
                sourceOfWealthCapital?: boolean;
                sourceOfWealthBusiness?: boolean;
                sourceOfWealthInheritance?: boolean;
                sourceOfWealthOther?: string;
                user?: {
                    id?: number;
                    documentId?: string;
                };
                documentIssuedBy?: string;
                /** @format date */
                dateOfBirth?: string;
                /** @format date-time */
                lastLogin?: string;
                language?: TokenLanguageEnum;
                currency?: TokenCurrencyEnum1;
                hsCookieId?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        tokens?: {
            id?: number;
            documentId?: string;
            sequence?: number;
            sold?: boolean;
            project?: {
                id?: number;
                documentId?: string;
            };
            uid?: string;
            order?: {
                id?: number;
                documentId?: string;
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        paymentType?: TokenPaymentTypeEnum;
        /** @format float */
        tokenCount?: number;
        currency?: TokenCurrencyEnum2;
        /**
         * @pattern ^\d*$
         * @example "123456789"
         */
        bankTransferReferenceCode?: string;
        /** @format float */
        price?: number;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface TokenResponse {
    data?: Token;
    meta?: object;
}

export interface UserProfileRequest {
    data: {
        firstName?: string;
        lastName?: string;
        bankAccounts?: any;
        phone?: string;
        residentialAddress?: string;
        cityAddress?: string;
        postalCode?: string;
        countryAddress?: string;
        socialSecurityNumber?: string;
        idOrPassportNumber?: string;
        countryOfOrigin?: string;
        citizenship?: string;
        /** @format date */
        documentValidUntil?: string;
        purposeOfContract?: string;
        expectedTradesVolume?: string;
        alimonyBenefitsPension?: boolean;
        salary?: boolean;
        business?: boolean;
        capital?: boolean;
        other?: string;
        noCriminalActivity?: boolean;
        countriesWithSanctions?: boolean;
        politicallyExposed?: boolean;
        sourceOfWealthSalary?: boolean;
        sourceOfWealthCapital?: boolean;
        sourceOfWealthBusiness?: boolean;
        sourceOfWealthInheritance?: boolean;
        sourceOfWealthOther?: string;
        /** @example "string or id" */
        user?: number | string;
        documentIssuedBy?: string;
        /** @format date */
        dateOfBirth?: string;
        /** @format date-time */
        lastLogin?: string;
        language?: UserProfileRequestLanguageEnum;
        currency?: UserProfileRequestCurrencyEnum;
        hsCookieId?: string;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface UserProfileListResponse {
    data?: UserProfile[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface UserProfile {
    id?: number;
    documentId?: string;
    firstName?: string;
    lastName?: string;
    bankAccounts?: any;
    phone?: string;
    residentialAddress?: string;
    cityAddress?: string;
    postalCode?: string;
    countryAddress?: string;
    socialSecurityNumber?: string;
    idOrPassportNumber?: string;
    countryOfOrigin?: string;
    citizenship?: string;
    /** @format date */
    documentValidUntil?: string;
    purposeOfContract?: string;
    expectedTradesVolume?: string;
    alimonyBenefitsPension?: boolean;
    salary?: boolean;
    business?: boolean;
    capital?: boolean;
    other?: string;
    noCriminalActivity?: boolean;
    countriesWithSanctions?: boolean;
    politicallyExposed?: boolean;
    sourceOfWealthSalary?: boolean;
    sourceOfWealthCapital?: boolean;
    sourceOfWealthBusiness?: boolean;
    sourceOfWealthInheritance?: boolean;
    sourceOfWealthOther?: string;
    user?: {
        id?: number;
        documentId?: string;
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
            id?: number;
            documentId?: string;
            name?: string;
            description?: string;
            type?: string;
            permissions?: {
                id?: number;
                documentId?: string;
                action?: string;
                role?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        code?: string;
                        description?: string;
                        users?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        permissions?: {
                            id?: number;
                            documentId?: string;
                            action?: string;
                            actionParameters?: any;
                            subject?: string;
                            properties?: any;
                            conditions?: any;
                            role?: {
                                id?: number;
                                documentId?: string;
                            };
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            users?: {
                id?: number;
                documentId?: string;
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        verification?: {
            id?: number;
            documentId?: string;
            relevance?: number;
            type?: UserProfileTypeEnum;
            timeToComplete?: number;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        canceled?: boolean;
        userProfile?: {
            id?: number;
            documentId?: string;
            firstName?: string;
            lastName?: string;
            bankAccounts?: any;
            phone?: string;
            residentialAddress?: string;
            cityAddress?: string;
            postalCode?: string;
            countryAddress?: string;
            socialSecurityNumber?: string;
            idOrPassportNumber?: string;
            countryOfOrigin?: string;
            citizenship?: string;
            /** @format date */
            documentValidUntil?: string;
            purposeOfContract?: string;
            expectedTradesVolume?: string;
            alimonyBenefitsPension?: boolean;
            salary?: boolean;
            business?: boolean;
            capital?: boolean;
            other?: string;
            noCriminalActivity?: boolean;
            countriesWithSanctions?: boolean;
            politicallyExposed?: boolean;
            sourceOfWealthSalary?: boolean;
            sourceOfWealthCapital?: boolean;
            sourceOfWealthBusiness?: boolean;
            sourceOfWealthInheritance?: boolean;
            sourceOfWealthOther?: string;
            user?: {
                id?: number;
                documentId?: string;
            };
            documentIssuedBy?: string;
            /** @format date */
            dateOfBirth?: string;
            /** @format date-time */
            lastLogin?: string;
            language?: UserProfileLanguageEnum;
            currency?: UserProfileCurrencyEnum;
            hsCookieId?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    documentIssuedBy?: string;
    /** @format date */
    dateOfBirth?: string;
    /** @format date-time */
    lastLogin?: string;
    language?: UserProfileLanguageEnum1;
    currency?: UserProfileCurrencyEnum1;
    hsCookieId?: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface UserProfileResponse {
    data?: UserProfile;
    meta?: object;
}

export interface UserVerificationRequest {
    data: {
        relevance: number;
        type: UserVerificationRequestTypeEnum;
        timeToComplete: number;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface UserVerificationListResponse {
    data?: UserVerification[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface UserVerification {
    id?: number;
    documentId?: string;
    relevance: number;
    type: UserVerificationTypeEnum;
    timeToComplete: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
        firstname?: string;
        lastname?: string;
        username?: string;
        /** @format email */
        email?: string;
        resetPasswordToken?: string;
        registrationToken?: string;
        isActive?: boolean;
        roles?: {
            id?: number;
            documentId?: string;
            name?: string;
            code?: string;
            description?: string;
            users?: {
                id?: number;
                documentId?: string;
            }[];
            permissions?: {
                id?: number;
                documentId?: string;
                action?: string;
                actionParameters?: any;
                subject?: string;
                properties?: any;
                conditions?: any;
                role?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        blocked?: boolean;
        preferedLanguage?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
        relevance?: number;
        type?: UserVerificationTypeEnum1;
        timeToComplete?: number;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
}

export interface UserVerificationResponse {
    data?: UserVerification;
    meta?: object;
}

export interface VotingRequest {
    data: {
        voteType: VotingRequestVoteTypeEnum;
        /** @format date-time */
        startAt: string;
        /** @format date-time */
        endAt?: string;
        state?: VotingRequestStateEnum;
        permission: VotingRequestPermissionEnum;
        question?: string;
        options?: VotingOptionComponent[];
        results?: (number | string)[];
        /** @example "string or id" */
        document?: number | string;
        /** @example "string or id" */
        project?: number | string;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface VotingListResponse {
    data?: Voting[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface Voting {
    id?: number;
    documentId?: string;
    voteType: VotingVoteTypeEnum;
    /** @format date-time */
    startAt: string;
    /** @format date-time */
    endAt?: string;
    state?: VotingStateEnum;
    permission: VotingPermissionEnum;
    question?: string;
    options?: VotingOptionComponent[];
    results?: {
        id?: number;
        documentId?: string;
        user?: {
            id?: number;
            documentId?: string;
            username?: string;
            /** @format email */
            email?: string;
            provider?: string;
            resetPasswordToken?: string;
            confirmationToken?: string;
            confirmed?: boolean;
            blocked?: boolean;
            role?: {
                id?: number;
                documentId?: string;
                name?: string;
                description?: string;
                type?: string;
                permissions?: {
                    id?: number;
                    documentId?: string;
                    action?: string;
                    role?: {
                        id?: number;
                        documentId?: string;
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                        firstname?: string;
                        lastname?: string;
                        username?: string;
                        /** @format email */
                        email?: string;
                        resetPasswordToken?: string;
                        registrationToken?: string;
                        isActive?: boolean;
                        roles?: {
                            id?: number;
                            documentId?: string;
                            name?: string;
                            code?: string;
                            description?: string;
                            users?: {
                                id?: number;
                                documentId?: string;
                            }[];
                            permissions?: {
                                id?: number;
                                documentId?: string;
                                action?: string;
                                actionParameters?: any;
                                subject?: string;
                                properties?: any;
                                conditions?: any;
                                role?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                /** @format date-time */
                                createdAt?: string;
                                /** @format date-time */
                                updatedAt?: string;
                                /** @format date-time */
                                publishedAt?: string;
                                createdBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                updatedBy?: {
                                    id?: number;
                                    documentId?: string;
                                };
                                locale?: string;
                                localizations?: {
                                    id?: number;
                                    documentId?: string;
                                }[];
                            }[];
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        blocked?: boolean;
                        preferedLanguage?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                users?: {
                    id?: number;
                    documentId?: string;
                }[];
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            verification?: {
                id?: number;
                documentId?: string;
                relevance?: number;
                type?: VotingTypeEnum;
                timeToComplete?: number;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            canceled?: boolean;
            userProfile?: {
                id?: number;
                documentId?: string;
                firstName?: string;
                lastName?: string;
                bankAccounts?: any;
                phone?: string;
                residentialAddress?: string;
                cityAddress?: string;
                postalCode?: string;
                countryAddress?: string;
                socialSecurityNumber?: string;
                idOrPassportNumber?: string;
                countryOfOrigin?: string;
                citizenship?: string;
                /** @format date */
                documentValidUntil?: string;
                purposeOfContract?: string;
                expectedTradesVolume?: string;
                alimonyBenefitsPension?: boolean;
                salary?: boolean;
                business?: boolean;
                capital?: boolean;
                other?: string;
                noCriminalActivity?: boolean;
                countriesWithSanctions?: boolean;
                politicallyExposed?: boolean;
                sourceOfWealthSalary?: boolean;
                sourceOfWealthCapital?: boolean;
                sourceOfWealthBusiness?: boolean;
                sourceOfWealthInheritance?: boolean;
                sourceOfWealthOther?: string;
                user?: {
                    id?: number;
                    documentId?: string;
                };
                documentIssuedBy?: string;
                /** @format date */
                dateOfBirth?: string;
                /** @format date-time */
                lastLogin?: string;
                language?: VotingLanguageEnum;
                currency?: VotingCurrencyEnum;
                hsCookieId?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        voting?: {
            id?: number;
            documentId?: string;
            voteType?: VotingVoteTypeEnum1;
            /** @format date-time */
            startAt?: string;
            /** @format date-time */
            endAt?: string;
            state?: VotingStateEnum1;
            permission?: VotingPermissionEnum1;
            question?: string;
            options?: VotingOptionComponent[];
            results?: {
                id?: number;
                documentId?: string;
            }[];
            document?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    pathId?: number;
                    parent?: {
                        id?: number;
                        documentId?: string;
                    };
                    children?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    files?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        alternativeText?: string;
                        caption?: string;
                        width?: number;
                        height?: number;
                        formats?: any;
                        hash?: string;
                        ext?: string;
                        mime?: string;
                        /** @format float */
                        size?: number;
                        url?: string;
                        previewUrl?: string;
                        provider?: string;
                        provider_metadata?: any;
                        related?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        folder?: {
                            id?: number;
                            documentId?: string;
                        };
                        folderPath?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    path?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            project?: {
                id?: number;
                documentId?: string;
                images?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    alternativeText?: string;
                    caption?: string;
                    width?: number;
                    height?: number;
                    formats?: any;
                    hash?: string;
                    ext?: string;
                    mime?: string;
                    /** @format float */
                    size?: number;
                    url?: string;
                    previewUrl?: string;
                    provider?: string;
                    provider_metadata?: any;
                    related?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    folder?: {
                        id?: number;
                        documentId?: string;
                    };
                    folderPath?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                layout?: ProjectLayoutComponent;
                /** @format date */
                completedOn?: string;
                /** @format date */
                closedOn?: string;
                /** @format date */
                expectedOn?: string;
                calculation?: ProjectCalculationComponent;
                tokenTotalCount?: number;
                minPurchaseQty?: number;
                maxPurchaseQty?: number;
                latitude?: string;
                longitude?: string;
                countryCode?: string;
                onlyForCurrentInvestor?: boolean;
                currency?: VotingCurrencyEnum1;
                tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
                state?: VotingStateEnum2;
                projectInfo?: {
                    id?: number;
                    documentId?: string;
                    title?: string;
                    slug?: string;
                    informationProspect?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        alternativeText?: string;
                        caption?: string;
                        width?: number;
                        height?: number;
                        formats?: any;
                        hash?: string;
                        ext?: string;
                        mime?: string;
                        /** @format float */
                        size?: number;
                        url?: string;
                        previewUrl?: string;
                        provider?: string;
                        provider_metadata?: any;
                        related?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        folder?: {
                            id?: number;
                            documentId?: string;
                        };
                        folderPath?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    };
                    description?: ProjectDescriptionWithTitleComponent[];
                    documents?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        alternativeText?: string;
                        caption?: string;
                        width?: number;
                        height?: number;
                        formats?: any;
                        hash?: string;
                        ext?: string;
                        mime?: string;
                        /** @format float */
                        size?: number;
                        url?: string;
                        previewUrl?: string;
                        provider?: string;
                        provider_metadata?: any;
                        related?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        folder?: {
                            id?: number;
                            documentId?: string;
                        };
                        folderPath?: string;
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    project?: {
                        id?: number;
                        documentId?: string;
                    };
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                internalName?: string;
                /** @format float */
                tokenTotalPrice?: number;
                /** @format float */
                tokenFee?: number;
                tokenGenerationState?: VotingTokenGenerationStateEnum;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format float */
        weight?: number;
        optionKey?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    }[];
    document?: {
        id?: number;
        documentId?: string;
        name?: string;
        alternativeText?: string;
        caption?: string;
        width?: number;
        height?: number;
        formats?: any;
        hash?: string;
        ext?: string;
        mime?: string;
        /** @format float */
        size?: number;
        url?: string;
        previewUrl?: string;
        provider?: string;
        provider_metadata?: any;
        related?: {
            id?: number;
            documentId?: string;
        }[];
        folder?: {
            id?: number;
            documentId?: string;
        };
        folderPath?: string;
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    project?: {
        id?: number;
        documentId?: string;
    };
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface VotingResponse {
    data?: Voting;
    meta?: object;
}

export interface VotingOptionComponent {
    id?: number;
    option?: string;
    key?: string;
}

export interface VotingResultRequest {
    data: {
        /** @example "string or id" */
        user?: number | string;
        /** @example "string or id" */
        voting?: number | string;
        /** @format float */
        weight?: number;
        optionKey: string;
        locale?: string;
        localizations?: (number | string)[];
    };
}

export interface VotingResultListResponse {
    data?: VotingResult[];
    meta?: {
        pagination?: {
            page?: number;
            /** @min 25 */
            pageSize?: number;
            /** @max 1 */
            pageCount?: number;
            total?: number;
        };
    };
}

export interface VotingResult {
    id?: number;
    documentId?: string;
    user?: {
        id?: number;
        documentId?: string;
        username?: string;
        /** @format email */
        email?: string;
        provider?: string;
        resetPasswordToken?: string;
        confirmationToken?: string;
        confirmed?: boolean;
        blocked?: boolean;
        role?: {
            id?: number;
            documentId?: string;
            name?: string;
            description?: string;
            type?: string;
            permissions?: {
                id?: number;
                documentId?: string;
                action?: string;
                role?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                    firstname?: string;
                    lastname?: string;
                    username?: string;
                    /** @format email */
                    email?: string;
                    resetPasswordToken?: string;
                    registrationToken?: string;
                    isActive?: boolean;
                    roles?: {
                        id?: number;
                        documentId?: string;
                        name?: string;
                        code?: string;
                        description?: string;
                        users?: {
                            id?: number;
                            documentId?: string;
                        }[];
                        permissions?: {
                            id?: number;
                            documentId?: string;
                            action?: string;
                            actionParameters?: any;
                            subject?: string;
                            properties?: any;
                            conditions?: any;
                            role?: {
                                id?: number;
                                documentId?: string;
                            };
                            /** @format date-time */
                            createdAt?: string;
                            /** @format date-time */
                            updatedAt?: string;
                            /** @format date-time */
                            publishedAt?: string;
                            createdBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            updatedBy?: {
                                id?: number;
                                documentId?: string;
                            };
                            locale?: string;
                            localizations?: {
                                id?: number;
                                documentId?: string;
                            }[];
                        }[];
                        /** @format date-time */
                        createdAt?: string;
                        /** @format date-time */
                        updatedAt?: string;
                        /** @format date-time */
                        publishedAt?: string;
                        createdBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        updatedBy?: {
                            id?: number;
                            documentId?: string;
                        };
                        locale?: string;
                        localizations?: {
                            id?: number;
                            documentId?: string;
                        }[];
                    }[];
                    blocked?: boolean;
                    preferedLanguage?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            users?: {
                id?: number;
                documentId?: string;
            }[];
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        verification?: {
            id?: number;
            documentId?: string;
            relevance?: number;
            type?: VotingResultTypeEnum;
            timeToComplete?: number;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        canceled?: boolean;
        userProfile?: {
            id?: number;
            documentId?: string;
            firstName?: string;
            lastName?: string;
            bankAccounts?: any;
            phone?: string;
            residentialAddress?: string;
            cityAddress?: string;
            postalCode?: string;
            countryAddress?: string;
            socialSecurityNumber?: string;
            idOrPassportNumber?: string;
            countryOfOrigin?: string;
            citizenship?: string;
            /** @format date */
            documentValidUntil?: string;
            purposeOfContract?: string;
            expectedTradesVolume?: string;
            alimonyBenefitsPension?: boolean;
            salary?: boolean;
            business?: boolean;
            capital?: boolean;
            other?: string;
            noCriminalActivity?: boolean;
            countriesWithSanctions?: boolean;
            politicallyExposed?: boolean;
            sourceOfWealthSalary?: boolean;
            sourceOfWealthCapital?: boolean;
            sourceOfWealthBusiness?: boolean;
            sourceOfWealthInheritance?: boolean;
            sourceOfWealthOther?: string;
            user?: {
                id?: number;
                documentId?: string;
            };
            documentIssuedBy?: string;
            /** @format date */
            dateOfBirth?: string;
            /** @format date-time */
            lastLogin?: string;
            language?: VotingResultLanguageEnum;
            currency?: VotingResultCurrencyEnum;
            hsCookieId?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    voting?: {
        id?: number;
        documentId?: string;
        voteType?: VotingResultVoteTypeEnum;
        /** @format date-time */
        startAt?: string;
        /** @format date-time */
        endAt?: string;
        state?: VotingResultStateEnum;
        permission?: VotingResultPermissionEnum;
        question?: string;
        options?: VotingOptionComponent[];
        results?: {
            id?: number;
            documentId?: string;
            user?: {
                id?: number;
                documentId?: string;
            };
            voting?: {
                id?: number;
                documentId?: string;
            };
            /** @format float */
            weight?: number;
            optionKey?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        }[];
        document?: {
            id?: number;
            documentId?: string;
            name?: string;
            alternativeText?: string;
            caption?: string;
            width?: number;
            height?: number;
            formats?: any;
            hash?: string;
            ext?: string;
            mime?: string;
            /** @format float */
            size?: number;
            url?: string;
            previewUrl?: string;
            provider?: string;
            provider_metadata?: any;
            related?: {
                id?: number;
                documentId?: string;
            }[];
            folder?: {
                id?: number;
                documentId?: string;
                name?: string;
                pathId?: number;
                parent?: {
                    id?: number;
                    documentId?: string;
                };
                children?: {
                    id?: number;
                    documentId?: string;
                }[];
                files?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    alternativeText?: string;
                    caption?: string;
                    width?: number;
                    height?: number;
                    formats?: any;
                    hash?: string;
                    ext?: string;
                    mime?: string;
                    /** @format float */
                    size?: number;
                    url?: string;
                    previewUrl?: string;
                    provider?: string;
                    provider_metadata?: any;
                    related?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    folder?: {
                        id?: number;
                        documentId?: string;
                    };
                    folderPath?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                path?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            folderPath?: string;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        project?: {
            id?: number;
            documentId?: string;
            images?: {
                id?: number;
                documentId?: string;
                name?: string;
                alternativeText?: string;
                caption?: string;
                width?: number;
                height?: number;
                formats?: any;
                hash?: string;
                ext?: string;
                mime?: string;
                /** @format float */
                size?: number;
                url?: string;
                previewUrl?: string;
                provider?: string;
                provider_metadata?: any;
                related?: {
                    id?: number;
                    documentId?: string;
                }[];
                folder?: {
                    id?: number;
                    documentId?: string;
                };
                folderPath?: string;
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            }[];
            layout?: ProjectLayoutComponent;
            /** @format date */
            completedOn?: string;
            /** @format date */
            closedOn?: string;
            /** @format date */
            expectedOn?: string;
            calculation?: ProjectCalculationComponent;
            tokenTotalCount?: number;
            minPurchaseQty?: number;
            maxPurchaseQty?: number;
            latitude?: string;
            longitude?: string;
            countryCode?: string;
            onlyForCurrentInvestor?: boolean;
            currency?: VotingResultCurrencyEnum1;
            tokenCurrentNominalPrice?: ProjectCurrentPriceComponent[];
            state?: VotingResultStateEnum1;
            projectInfo?: {
                id?: number;
                documentId?: string;
                title?: string;
                slug?: string;
                informationProspect?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    alternativeText?: string;
                    caption?: string;
                    width?: number;
                    height?: number;
                    formats?: any;
                    hash?: string;
                    ext?: string;
                    mime?: string;
                    /** @format float */
                    size?: number;
                    url?: string;
                    previewUrl?: string;
                    provider?: string;
                    provider_metadata?: any;
                    related?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    folder?: {
                        id?: number;
                        documentId?: string;
                    };
                    folderPath?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                };
                description?: ProjectDescriptionWithTitleComponent[];
                documents?: {
                    id?: number;
                    documentId?: string;
                    name?: string;
                    alternativeText?: string;
                    caption?: string;
                    width?: number;
                    height?: number;
                    formats?: any;
                    hash?: string;
                    ext?: string;
                    mime?: string;
                    /** @format float */
                    size?: number;
                    url?: string;
                    previewUrl?: string;
                    provider?: string;
                    provider_metadata?: any;
                    related?: {
                        id?: number;
                        documentId?: string;
                    }[];
                    folder?: {
                        id?: number;
                        documentId?: string;
                    };
                    folderPath?: string;
                    /** @format date-time */
                    createdAt?: string;
                    /** @format date-time */
                    updatedAt?: string;
                    /** @format date-time */
                    publishedAt?: string;
                    createdBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    updatedBy?: {
                        id?: number;
                        documentId?: string;
                    };
                    locale?: string;
                    localizations?: {
                        id?: number;
                        documentId?: string;
                    }[];
                }[];
                project?: {
                    id?: number;
                    documentId?: string;
                };
                /** @format date-time */
                createdAt?: string;
                /** @format date-time */
                updatedAt?: string;
                /** @format date-time */
                publishedAt?: string;
                createdBy?: {
                    id?: number;
                    documentId?: string;
                };
                updatedBy?: {
                    id?: number;
                    documentId?: string;
                };
                locale?: string;
                localizations?: {
                    id?: number;
                    documentId?: string;
                }[];
            };
            internalName?: string;
            /** @format float */
            tokenTotalPrice?: number;
            /** @format float */
            tokenFee?: number;
            tokenGenerationState?: VotingResultTokenGenerationStateEnum;
            /** @format date-time */
            createdAt?: string;
            /** @format date-time */
            updatedAt?: string;
            /** @format date-time */
            publishedAt?: string;
            createdBy?: {
                id?: number;
                documentId?: string;
            };
            updatedBy?: {
                id?: number;
                documentId?: string;
            };
            locale?: string;
            localizations?: {
                id?: number;
                documentId?: string;
            }[];
        };
        /** @format date-time */
        createdAt?: string;
        /** @format date-time */
        updatedAt?: string;
        /** @format date-time */
        publishedAt?: string;
        createdBy?: {
            id?: number;
            documentId?: string;
        };
        updatedBy?: {
            id?: number;
            documentId?: string;
        };
        locale?: string;
        localizations?: {
            id?: number;
            documentId?: string;
        }[];
    };
    /** @format float */
    weight?: number;
    optionKey: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
    /** @format date-time */
    publishedAt?: string;
    createdBy?: {
        id?: number;
        documentId?: string;
    };
    updatedBy?: {
        id?: number;
        documentId?: string;
    };
    locale?: string;
    localizations?: {
        id?: number;
        documentId?: string;
    }[];
}

export interface VotingResultResponse {
    data?: VotingResult;
    meta?: object;
}

export interface UploadFile {
    id?: number;
    name?: string;
    alternativeText?: string;
    caption?: string;
    /** @format integer */
    width?: number;
    /** @format integer */
    height?: number;
    formats?: number;
    hash?: string;
    ext?: string;
    mime?: string;
    /** @format double */
    size?: number;
    url?: string;
    previewUrl?: string;
    provider?: string;
    provider_metadata?: object;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
}

export interface UsersPermissionsRole {
    id?: number;
    name?: string;
    description?: string;
    type?: string;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    updatedAt?: string;
}

export interface UsersPermissionsUser {
    /** @example 1 */
    id?: number;
    /** @example "foo.bar" */
    username?: string;
    /** @example "foo.bar@strapi.io" */
    email?: string;
    /** @example "local" */
    provider?: string;
    /** @example true */
    confirmed?: boolean;
    /** @example false */
    blocked?: boolean;
    /**
     * @format date-time
     * @example "2022-06-02T08:32:06.258Z"
     */
    createdAt?: string;
    /**
     * @format date-time
     * @example "2022-06-02T08:32:06.267Z"
     */
    updatedAt?: string;
}

export interface UsersPermissionsUserRegistration {
    /** @example "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" */
    jwt?: string;
    user?: UsersPermissionsUser;
}

export type UsersPermissionsPermissionsTree = Record<
    string,
    {
        /** every controller of the api */
        controllers?: Record<
            string,
            Record<
                string,
                {
                    enabled?: boolean;
                    policy?: string;
                }
            >
        >;
    }
>;

export enum ArticleRequestTypeEnum {
    News = "News",
    HowTo = "HowTo",
    InvestorClub = "InvestorClub",
}

export enum ArticleTypeEnum {
    News = "News",
    HowTo = "HowTo",
    InvestorClub = "InvestorClub",
}

export enum ArticleTypeEnum1 {
    News = "News",
    HowTo = "HowTo",
    InvestorClub = "InvestorClub",
}

export enum ArticlePermissionsComponentTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum ArticlePermissionsComponentLanguageEnum {
    En = "en",
    Cs = "cs",
}

export enum ArticlePermissionsComponentCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum ArticlePermissionsComponentCurrencyEnum1 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum ArticlePermissionsComponentStateEnum {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum ArticlePermissionsComponentTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum ArticlePermissionsComponentTypeEnum1 {
    Public = "Public",
    Private = "Private",
    OnlyInvestors = "OnlyInvestors",
}

export enum ProjectLayoutComponentEnergyClassEnum {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
}

export enum ProjectLayoutComponentMaterialEnum {
    Brick = "brick",
    Panel = "panel",
    Wood = "wood",
}

export enum BannerRequestPageEnum {
    Dashboard = "dashboard",
    Orders = "orders",
    Projects = "projects",
    Voting = "voting",
    News = "news",
    InvestorClub = "investorClub",
    HowTo = "howTo",
}

export enum BannerRequestConditionEnum {
    NoKYC = "noKYC",
    NoAML = "noAML",
    NeverOrdered = "neverOrdered",
    NoShares = "noShares",
    AlwaysVisible = "alwaysVisible",
}

export enum BannerPageEnum {
    Dashboard = "dashboard",
    Orders = "orders",
    Projects = "projects",
    Voting = "voting",
    News = "news",
    InvestorClub = "investorClub",
    HowTo = "howTo",
}

export enum BannerConditionEnum {
    NoKYC = "noKYC",
    NoAML = "noAML",
    NeverOrdered = "neverOrdered",
    NoShares = "noShares",
    AlwaysVisible = "alwaysVisible",
}

export enum BannerPageEnum1 {
    Dashboard = "dashboard",
    Orders = "orders",
    Projects = "projects",
    Voting = "voting",
    News = "news",
    InvestorClub = "investorClub",
    HowTo = "howTo",
}

export enum BannerConditionEnum1 {
    NoKYC = "noKYC",
    NoAML = "noAML",
    NeverOrdered = "neverOrdered",
    NoShares = "noShares",
    AlwaysVisible = "alwaysVisible",
}

export enum FundedRequestCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum FundedRequestStateEnum {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum FundedRequestTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum FundedRequestTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum FundedRequestLanguageEnum {
    En = "en",
    Cs = "cs",
}

export enum FundedRequestCurrencyEnum1 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum MessageRequestTypeEnum {
    Verification = "Verification",
    Greenhorn = "Greenhorn",
    Normal = "Normal",
}

export enum MessageTypeEnum {
    Verification = "Verification",
    Greenhorn = "Greenhorn",
    Normal = "Normal",
}

export enum MessageTypeEnum1 {
    Verification = "Verification",
    Greenhorn = "Greenhorn",
    Normal = "Normal",
}

export enum OrderRequestStateEnum {
    Open = "Open",
    Unpaid = "Unpaid",
    Failed = "Failed",
    Paid = "Paid",
    Canceled = "Canceled",
    AdminCancel = "AdminCancel",
    Timeout = "Timeout",
}

export enum OrderRequestPaymentTypeEnum {
    CsobPaymentGateway = "CsobPaymentGateway",
    ConfirmoPaymentGateway = "ConfirmoPaymentGateway",
    PayoutPaymentGateway = "PayoutPaymentGateway",
    BankTransfer = "BankTransfer",
    Bonus = "Bonus",
}

export enum OrderRequestCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum OrderStateEnum {
    Open = "Open",
    Unpaid = "Unpaid",
    Failed = "Failed",
    Paid = "Paid",
    Canceled = "Canceled",
    AdminCancel = "AdminCancel",
    Timeout = "Timeout",
}

export enum OrderCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum OrderStateEnum1 {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum OrderTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum OrderTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum OrderLanguageEnum {
    En = "en",
    Cs = "cs",
}

export enum OrderCurrencyEnum1 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum OrderStateEnum2 {
    Open = "Open",
    Unpaid = "Unpaid",
    Failed = "Failed",
    Paid = "Paid",
    Canceled = "Canceled",
    AdminCancel = "AdminCancel",
    Timeout = "Timeout",
}

export enum OrderPaymentTypeEnum {
    CsobPaymentGateway = "CsobPaymentGateway",
    ConfirmoPaymentGateway = "ConfirmoPaymentGateway",
    PayoutPaymentGateway = "PayoutPaymentGateway",
    BankTransfer = "BankTransfer",
    Bonus = "Bonus",
}

export enum OrderCurrencyEnum2 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum OrderPaymentTypeEnum1 {
    CsobPaymentGateway = "CsobPaymentGateway",
    ConfirmoPaymentGateway = "ConfirmoPaymentGateway",
    PayoutPaymentGateway = "PayoutPaymentGateway",
    BankTransfer = "BankTransfer",
    Bonus = "Bonus",
}

export enum OrderCurrencyEnum3 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum ProjectRequestCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum ProjectRequestStateEnum {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum ProjectRequestTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum ProjectCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum ProjectStateEnum {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum ProjectCurrencyEnum1 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum ProjectStateEnum1 {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum ProjectTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum ProjectTokenGenerationStateEnum1 {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum ProjectInfoCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum ProjectInfoStateEnum {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum ProjectInfoTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum TagTypeEnum {
    News = "News",
    HowTo = "HowTo",
    InvestorClub = "InvestorClub",
}

export enum TokenCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum TokenStateEnum {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum TokenTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum TokenStateEnum1 {
    Open = "Open",
    Unpaid = "Unpaid",
    Failed = "Failed",
    Paid = "Paid",
    Canceled = "Canceled",
    AdminCancel = "AdminCancel",
    Timeout = "Timeout",
}

export enum TokenTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum TokenLanguageEnum {
    En = "en",
    Cs = "cs",
}

export enum TokenCurrencyEnum1 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum TokenPaymentTypeEnum {
    CsobPaymentGateway = "CsobPaymentGateway",
    ConfirmoPaymentGateway = "ConfirmoPaymentGateway",
    PayoutPaymentGateway = "PayoutPaymentGateway",
    BankTransfer = "BankTransfer",
    Bonus = "Bonus",
}

export enum TokenCurrencyEnum2 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum UserProfileRequestLanguageEnum {
    En = "en",
    Cs = "cs",
}

export enum UserProfileRequestCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum UserProfileTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum UserProfileLanguageEnum {
    En = "en",
    Cs = "cs",
}

export enum UserProfileCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum UserProfileLanguageEnum1 {
    En = "en",
    Cs = "cs",
}

export enum UserProfileCurrencyEnum1 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum UserVerificationRequestTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum UserVerificationTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum UserVerificationTypeEnum1 {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum VotingRequestVoteTypeEnum {
    PerUser = "PerUser",
    PerShare = "PerShare",
}

export enum VotingRequestStateEnum {
    Current = "Current",
    Future = "Future",
    Past = "Past",
}

export enum VotingRequestPermissionEnum {
    Public = "Public",
    Internal = "Internal",
}

export enum VotingVoteTypeEnum {
    PerUser = "PerUser",
    PerShare = "PerShare",
}

export enum VotingStateEnum {
    Current = "Current",
    Future = "Future",
    Past = "Past",
}

export enum VotingPermissionEnum {
    Public = "Public",
    Internal = "Internal",
}

export enum VotingTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum VotingLanguageEnum {
    En = "en",
    Cs = "cs",
}

export enum VotingCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum VotingVoteTypeEnum1 {
    PerUser = "PerUser",
    PerShare = "PerShare",
}

export enum VotingStateEnum1 {
    Current = "Current",
    Future = "Future",
    Past = "Past",
}

export enum VotingPermissionEnum1 {
    Public = "Public",
    Internal = "Internal",
}

export enum VotingCurrencyEnum1 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum VotingStateEnum2 {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum VotingTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export enum VotingResultTypeEnum {
    SIGNUP = "SIGNUP",
    KYC = "KYC",
    AML = "AML",
    ORDER = "ORDER",
    INVESTOR = "INVESTOR",
}

export enum VotingResultLanguageEnum {
    En = "en",
    Cs = "cs",
}

export enum VotingResultCurrencyEnum {
    CZK = "CZK",
    EUR = "EUR",
}

export enum VotingResultVoteTypeEnum {
    PerUser = "PerUser",
    PerShare = "PerShare",
}

export enum VotingResultStateEnum {
    Current = "Current",
    Future = "Future",
    Past = "Past",
}

export enum VotingResultPermissionEnum {
    Public = "Public",
    Internal = "Internal",
}

export enum VotingResultCurrencyEnum1 {
    CZK = "CZK",
    EUR = "EUR",
}

export enum VotingResultStateEnum1 {
    Closed = "Closed",
    Open = "Open",
    Prepared = "Prepared",
    Unknown = "Unknown",
    Completed = "Completed",
}

export enum VotingResultTokenGenerationStateEnum {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Completed = "Completed",
}

export interface GetAppConfigurationParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetAppConfigurationData = AppConfigurationResponse;

export type PutAppConfigurationData = AppConfigurationResponse;

/** @format int64 */
export type DeleteAppConfigurationData = number;

export interface GetArticlesParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetArticlesData = ArticleListResponse;

export type PostArticlesData = ArticleResponse;

export type GetArticlesIdData = ArticleResponse;

export type PutArticlesIdData = ArticleResponse;

/** @format int64 */
export type DeleteArticlesIdData = number;

export interface GetBannersParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetBannersData = BannerListResponse;

export type PostBannersData = BannerResponse;

export type GetBannersIdData = BannerResponse;

export type PutBannersIdData = BannerResponse;

/** @format int64 */
export type DeleteBannersIdData = number;

export interface GetFaqsParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetFaqsData = FaqListResponse;

export type PostFaqsData = FaqResponse;

export type GetFaqsIdData = FaqResponse;

export type PutFaqsIdData = FaqResponse;

/** @format int64 */
export type DeleteFaqsIdData = number;

export interface GetFundedRequestsParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetFundedRequestsData = FundedRequestListResponse;

export type PostFundedRequestsData = FundedRequestResponse;

export type GetFundedRequestsIdData = FundedRequestResponse;

export type PutFundedRequestsIdData = FundedRequestResponse;

/** @format int64 */
export type DeleteFundedRequestsIdData = number;

export interface GetMessagesParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetMessagesData = MessageListResponse;

export type PostMessagesData = MessageResponse;

export type GetMessagesIdData = MessageResponse;

export type PutMessagesIdData = MessageResponse;

/** @format int64 */
export type DeleteMessagesIdData = number;

export interface GetOrdersParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetOrdersData = OrderListResponse;

export type PostOrdersData = OrderResponse;

export type GetOrdersIdData = OrderResponse;

export type PutOrdersIdData = OrderResponse;

/** @format int64 */
export type DeleteOrdersIdData = number;

export interface GetProjectsParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetProjectsData = ProjectListResponse;

export type PostProjectsData = ProjectResponse;

export type GetProjectsIdData = ProjectResponse;

export type PutProjectsIdData = ProjectResponse;

/** @format int64 */
export type DeleteProjectsIdData = number;

export interface GetProjectInfosParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetProjectInfosData = ProjectInfoListResponse;

export type PostProjectInfosData = ProjectInfoResponse;

export type GetProjectInfosIdData = ProjectInfoResponse;

export type PutProjectInfosIdData = ProjectInfoResponse;

/** @format int64 */
export type DeleteProjectInfosIdData = number;

export interface GetTagsParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetTagsData = TagListResponse;

export type PostTagsData = TagResponse;

export type GetTagsIdData = TagResponse;

export type PutTagsIdData = TagResponse;

/** @format int64 */
export type DeleteTagsIdData = number;

export interface GetTokensParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetTokensData = TokenListResponse;

export type PostTokensData = TokenResponse;

export type GetTokensIdData = TokenResponse;

export type PutTokensIdData = TokenResponse;

/** @format int64 */
export type DeleteTokensIdData = number;

export interface GetUserProfilesParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetUserProfilesData = UserProfileListResponse;

export type PostUserProfilesData = UserProfileResponse;

export type GetUserProfilesIdData = UserProfileResponse;

export type PutUserProfilesIdData = UserProfileResponse;

/** @format int64 */
export type DeleteUserProfilesIdData = number;

export interface GetUserVerificationsParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetUserVerificationsData = UserVerificationListResponse;

export type PostUserVerificationsData = UserVerificationResponse;

export type GetUserVerificationsIdData = UserVerificationResponse;

export type PutUserVerificationsIdData = UserVerificationResponse;

/** @format int64 */
export type DeleteUserVerificationsIdData = number;

export interface GetVotingsParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetVotingsData = VotingListResponse;

export type PostVotingsData = VotingResponse;

export type GetVotingsIdData = VotingResponse;

export type PutVotingsIdData = VotingResponse;

/** @format int64 */
export type DeleteVotingsIdData = number;

export interface GetVotingResultsParams {
    /** Sort by attributes ascending (asc) or descending (desc) */
    sort?: string;
    /** Return page/pageSize (default: true) */
    "pagination[withCount]"?: boolean;
    /** Page number (default: 0) */
    "pagination[page]"?: number;
    /** Page size (default: 25) */
    "pagination[pageSize]"?: number;
    /** Offset value (default: 0) */
    "pagination[start]"?: number;
    /** Number of entities to return (default: 25) */
    "pagination[limit]"?: number;
    /** Fields to return (ex: title,author) */
    fields?: string;
    /** Relations to return */
    populate?: string;
    /** Filters to apply */
    filters?: Record<string, any>;
    /** Locale to apply */
    locale?: string;
}

export type GetVotingResultsData = VotingResultListResponse;

export type PostVotingResultsData = VotingResultResponse;

export type GetVotingResultsIdData = VotingResultResponse;

export type PutVotingResultsIdData = VotingResultResponse;

/** @format int64 */
export type DeleteVotingResultsIdData = number;

export interface UploadCreatePayload {
    /** The folder where the file(s) will be uploaded to (only supported on strapi-provider-upload-aws-s3). */
    path?: string;
    /** The ID of the entry which the file(s) will be linked to */
    refId?: string;
    /** The unique ID (uid) of the model which the file(s) will be linked to (api::restaurant.restaurant). */
    ref?: string;
    /** The field of the entry which the file(s) will be precisely linked to. */
    field?: string;
    files: File[];
}

export type UploadCreateData = UploadFile[];

export interface UploadIdCreatePayload {
    fileInfo?: {
        name?: string;
        alternativeText?: string;
        caption?: string;
    };
    /** @format binary */
    files?: File;
}

export interface UploadIdCreateParams {
    id: string;
}

export type UploadIdCreateData = UploadFile[];

export type FilesListData = UploadFile[];

export type FilesDetailData = UploadFile;

export type FilesDeleteData = UploadFile;

export interface LocalCreatePayload {
    identifier?: string;
    password?: string;
}

export type LocalCreateData = UsersPermissionsUserRegistration;

export interface LocalRegisterCreatePayload {
    username?: string;
    email?: string;
    password?: string;
}

export type LocalRegisterCreateData = UsersPermissionsUserRegistration;

export type CallbackDetailData = UsersPermissionsUserRegistration;

export enum OkEnum {
    True = "true",
}

export interface ForgotPasswordCreatePayload {
    email?: string;
}

export interface ForgotPasswordCreateData {
    ok?: OkEnum;
}

export interface ResetPasswordCreatePayload {
    password?: string;
    passwordConfirmation?: string;
    code?: string;
}

export type ResetPasswordCreateData = UsersPermissionsUserRegistration;

export interface ChangePasswordCreatePayload {
    password: string;
    currentPassword: string;
    passwordConfirmation: string;
}

export type ChangePasswordCreateData = UsersPermissionsUserRegistration;

export interface EmailConfirmationListParams {
    /** confirmation token received by email */
    confirmation?: string;
}

export enum SentEnum {
    True = "true",
}

export interface SendEmailConfirmationCreatePayload {
    email?: string;
}

export interface SendEmailConfirmationCreateData {
    email?: string;
    sent?: SentEnum;
}

export interface PermissionsListData {
    permissions?: UsersPermissionsPermissionsTree;
}

export interface RolesListData {
    roles?: (UsersPermissionsRole & {
        nb_users?: number;
    })[];
}

export enum OkEnum1 {
    True = "true",
}

export interface RolesCreateData {
    ok?: OkEnum1;
}

export interface RolesDetailData {
    role?: UsersPermissionsRole;
}

export enum OkEnum2 {
    True = "true",
}

export interface RolesUpdateData {
    ok?: OkEnum2;
}

export enum OkEnum3 {
    True = "true",
}

export interface RolesDeleteData {
    ok?: OkEnum3;
}

export type UsersListData = UsersPermissionsUser[];

export interface UsersCreatePayload {
    email: string;
    username: string;
    password: string;
}

export type UsersCreateData = UsersPermissionsUser & {
    role?: UsersPermissionsRole;
};

export type UsersDetailData = UsersPermissionsUser;

export interface UsersUpdatePayload {
    email: string;
    username: string;
    password: string;
}

export type UsersUpdateData = UsersPermissionsUser & {
    role?: UsersPermissionsRole;
};

export type UsersDeleteData = UsersPermissionsUser;

export type GetUsersPermissionsUsersRolesData = UsersPermissionsUser;

export type CountListData = number;

export namespace AppConfiguration {
    /**
     * No description
     * @tags App-configuration
     * @name GetAppConfiguration
     * @request GET:/app-configuration
     * @secure
     */
    export namespace GetAppConfiguration {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetAppConfigurationData;
    }

    /**
     * No description
     * @tags App-configuration
     * @name PutAppConfiguration
     * @request PUT:/app-configuration
     * @secure
     */
    export namespace PutAppConfiguration {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = AppConfigurationRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutAppConfigurationData;
    }

    /**
     * No description
     * @tags App-configuration
     * @name DeleteAppConfiguration
     * @request DELETE:/app-configuration
     * @secure
     */
    export namespace DeleteAppConfiguration {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteAppConfigurationData;
    }
}

export namespace Article {
    /**
     * No description
     * @tags Article
     * @name GetArticles
     * @request GET:/articles
     * @secure
     */
    export namespace GetArticles {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetArticlesData;
    }

    /**
     * No description
     * @tags Article
     * @name PostArticles
     * @request POST:/articles
     * @secure
     */
    export namespace PostArticles {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ArticleRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostArticlesData;
    }

    /**
     * No description
     * @tags Article
     * @name GetArticlesId
     * @request GET:/articles/{id}
     * @secure
     */
    export namespace GetArticlesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetArticlesIdData;
    }

    /**
     * No description
     * @tags Article
     * @name PutArticlesId
     * @request PUT:/articles/{id}
     * @secure
     */
    export namespace PutArticlesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = ArticleRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutArticlesIdData;
    }

    /**
     * No description
     * @tags Article
     * @name DeleteArticlesId
     * @request DELETE:/articles/{id}
     * @secure
     */
    export namespace DeleteArticlesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteArticlesIdData;
    }
}

export namespace Banner {
    /**
     * No description
     * @tags Banner
     * @name GetBanners
     * @request GET:/banners
     * @secure
     */
    export namespace GetBanners {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetBannersData;
    }

    /**
     * No description
     * @tags Banner
     * @name PostBanners
     * @request POST:/banners
     * @secure
     */
    export namespace PostBanners {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = BannerRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostBannersData;
    }

    /**
     * No description
     * @tags Banner
     * @name GetBannersId
     * @request GET:/banners/{id}
     * @secure
     */
    export namespace GetBannersId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetBannersIdData;
    }

    /**
     * No description
     * @tags Banner
     * @name PutBannersId
     * @request PUT:/banners/{id}
     * @secure
     */
    export namespace PutBannersId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = BannerRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutBannersIdData;
    }

    /**
     * No description
     * @tags Banner
     * @name DeleteBannersId
     * @request DELETE:/banners/{id}
     * @secure
     */
    export namespace DeleteBannersId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteBannersIdData;
    }
}

export namespace Faq {
    /**
     * No description
     * @tags Faq
     * @name GetFaqs
     * @request GET:/faqs
     * @secure
     */
    export namespace GetFaqs {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetFaqsData;
    }

    /**
     * No description
     * @tags Faq
     * @name PostFaqs
     * @request POST:/faqs
     * @secure
     */
    export namespace PostFaqs {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = FaqRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostFaqsData;
    }

    /**
     * No description
     * @tags Faq
     * @name GetFaqsId
     * @request GET:/faqs/{id}
     * @secure
     */
    export namespace GetFaqsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetFaqsIdData;
    }

    /**
     * No description
     * @tags Faq
     * @name PutFaqsId
     * @request PUT:/faqs/{id}
     * @secure
     */
    export namespace PutFaqsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = FaqRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutFaqsIdData;
    }

    /**
     * No description
     * @tags Faq
     * @name DeleteFaqsId
     * @request DELETE:/faqs/{id}
     * @secure
     */
    export namespace DeleteFaqsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteFaqsIdData;
    }
}

export namespace FundedRequest {
    /**
     * No description
     * @tags Funded-request
     * @name GetFundedRequests
     * @request GET:/funded-requests
     * @secure
     */
    export namespace GetFundedRequests {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetFundedRequestsData;
    }

    /**
     * No description
     * @tags Funded-request
     * @name PostFundedRequests
     * @request POST:/funded-requests
     * @secure
     */
    export namespace PostFundedRequests {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = FundedRequestRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostFundedRequestsData;
    }

    /**
     * No description
     * @tags Funded-request
     * @name GetFundedRequestsId
     * @request GET:/funded-requests/{id}
     * @secure
     */
    export namespace GetFundedRequestsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetFundedRequestsIdData;
    }

    /**
     * No description
     * @tags Funded-request
     * @name PutFundedRequestsId
     * @request PUT:/funded-requests/{id}
     * @secure
     */
    export namespace PutFundedRequestsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = FundedRequestRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutFundedRequestsIdData;
    }

    /**
     * No description
     * @tags Funded-request
     * @name DeleteFundedRequestsId
     * @request DELETE:/funded-requests/{id}
     * @secure
     */
    export namespace DeleteFundedRequestsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteFundedRequestsIdData;
    }
}

export namespace Message {
    /**
     * No description
     * @tags Message
     * @name GetMessages
     * @request GET:/messages
     * @secure
     */
    export namespace GetMessages {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetMessagesData;
    }

    /**
     * No description
     * @tags Message
     * @name PostMessages
     * @request POST:/messages
     * @secure
     */
    export namespace PostMessages {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = MessageRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostMessagesData;
    }

    /**
     * No description
     * @tags Message
     * @name GetMessagesId
     * @request GET:/messages/{id}
     * @secure
     */
    export namespace GetMessagesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetMessagesIdData;
    }

    /**
     * No description
     * @tags Message
     * @name PutMessagesId
     * @request PUT:/messages/{id}
     * @secure
     */
    export namespace PutMessagesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = MessageRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutMessagesIdData;
    }

    /**
     * No description
     * @tags Message
     * @name DeleteMessagesId
     * @request DELETE:/messages/{id}
     * @secure
     */
    export namespace DeleteMessagesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteMessagesIdData;
    }
}

export namespace Order {
    /**
     * No description
     * @tags Order
     * @name GetOrders
     * @request GET:/orders
     * @secure
     */
    export namespace GetOrders {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetOrdersData;
    }

    /**
     * No description
     * @tags Order
     * @name PostOrders
     * @request POST:/orders
     * @secure
     */
    export namespace PostOrders {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = OrderRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostOrdersData;
    }

    /**
     * No description
     * @tags Order
     * @name GetOrdersId
     * @request GET:/orders/{id}
     * @secure
     */
    export namespace GetOrdersId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetOrdersIdData;
    }

    /**
     * No description
     * @tags Order
     * @name PutOrdersId
     * @request PUT:/orders/{id}
     * @secure
     */
    export namespace PutOrdersId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = OrderRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutOrdersIdData;
    }

    /**
     * No description
     * @tags Order
     * @name DeleteOrdersId
     * @request DELETE:/orders/{id}
     * @secure
     */
    export namespace DeleteOrdersId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteOrdersIdData;
    }
}

export namespace Project {
    /**
     * No description
     * @tags Project
     * @name GetProjects
     * @request GET:/projects
     * @secure
     */
    export namespace GetProjects {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetProjectsData;
    }

    /**
     * No description
     * @tags Project
     * @name PostProjects
     * @request POST:/projects
     * @secure
     */
    export namespace PostProjects {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ProjectRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostProjectsData;
    }

    /**
     * No description
     * @tags Project
     * @name GetProjectsId
     * @request GET:/projects/{id}
     * @secure
     */
    export namespace GetProjectsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetProjectsIdData;
    }

    /**
     * No description
     * @tags Project
     * @name PutProjectsId
     * @request PUT:/projects/{id}
     * @secure
     */
    export namespace PutProjectsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = ProjectRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutProjectsIdData;
    }

    /**
     * No description
     * @tags Project
     * @name DeleteProjectsId
     * @request DELETE:/projects/{id}
     * @secure
     */
    export namespace DeleteProjectsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteProjectsIdData;
    }
}

export namespace ProjectInfo {
    /**
     * No description
     * @tags Project-info
     * @name GetProjectInfos
     * @request GET:/project-infos
     * @secure
     */
    export namespace GetProjectInfos {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetProjectInfosData;
    }

    /**
     * No description
     * @tags Project-info
     * @name PostProjectInfos
     * @request POST:/project-infos
     * @secure
     */
    export namespace PostProjectInfos {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ProjectInfoRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostProjectInfosData;
    }

    /**
     * No description
     * @tags Project-info
     * @name GetProjectInfosId
     * @request GET:/project-infos/{id}
     * @secure
     */
    export namespace GetProjectInfosId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetProjectInfosIdData;
    }

    /**
     * No description
     * @tags Project-info
     * @name PutProjectInfosId
     * @request PUT:/project-infos/{id}
     * @secure
     */
    export namespace PutProjectInfosId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = ProjectInfoRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutProjectInfosIdData;
    }

    /**
     * No description
     * @tags Project-info
     * @name DeleteProjectInfosId
     * @request DELETE:/project-infos/{id}
     * @secure
     */
    export namespace DeleteProjectInfosId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteProjectInfosIdData;
    }
}

export namespace Tag {
    /**
     * No description
     * @tags Tag
     * @name GetTags
     * @request GET:/tags
     * @secure
     */
    export namespace GetTags {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetTagsData;
    }

    /**
     * No description
     * @tags Tag
     * @name PostTags
     * @request POST:/tags
     * @secure
     */
    export namespace PostTags {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = TagRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostTagsData;
    }

    /**
     * No description
     * @tags Tag
     * @name GetTagsId
     * @request GET:/tags/{id}
     * @secure
     */
    export namespace GetTagsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetTagsIdData;
    }

    /**
     * No description
     * @tags Tag
     * @name PutTagsId
     * @request PUT:/tags/{id}
     * @secure
     */
    export namespace PutTagsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = TagRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutTagsIdData;
    }

    /**
     * No description
     * @tags Tag
     * @name DeleteTagsId
     * @request DELETE:/tags/{id}
     * @secure
     */
    export namespace DeleteTagsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteTagsIdData;
    }
}

export namespace Token {
    /**
     * No description
     * @tags Token
     * @name GetTokens
     * @request GET:/tokens
     * @secure
     */
    export namespace GetTokens {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetTokensData;
    }

    /**
     * No description
     * @tags Token
     * @name PostTokens
     * @request POST:/tokens
     * @secure
     */
    export namespace PostTokens {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = TokenRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostTokensData;
    }

    /**
     * No description
     * @tags Token
     * @name GetTokensId
     * @request GET:/tokens/{id}
     * @secure
     */
    export namespace GetTokensId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetTokensIdData;
    }

    /**
     * No description
     * @tags Token
     * @name PutTokensId
     * @request PUT:/tokens/{id}
     * @secure
     */
    export namespace PutTokensId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = TokenRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutTokensIdData;
    }

    /**
     * No description
     * @tags Token
     * @name DeleteTokensId
     * @request DELETE:/tokens/{id}
     * @secure
     */
    export namespace DeleteTokensId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteTokensIdData;
    }
}

export namespace UserProfile {
    /**
     * No description
     * @tags User-profile
     * @name GetUserProfiles
     * @request GET:/user-profiles
     * @secure
     */
    export namespace GetUserProfiles {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetUserProfilesData;
    }

    /**
     * No description
     * @tags User-profile
     * @name PostUserProfiles
     * @request POST:/user-profiles
     * @secure
     */
    export namespace PostUserProfiles {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UserProfileRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostUserProfilesData;
    }

    /**
     * No description
     * @tags User-profile
     * @name GetUserProfilesId
     * @request GET:/user-profiles/{id}
     * @secure
     */
    export namespace GetUserProfilesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetUserProfilesIdData;
    }

    /**
     * No description
     * @tags User-profile
     * @name PutUserProfilesId
     * @request PUT:/user-profiles/{id}
     * @secure
     */
    export namespace PutUserProfilesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = UserProfileRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutUserProfilesIdData;
    }

    /**
     * No description
     * @tags User-profile
     * @name DeleteUserProfilesId
     * @request DELETE:/user-profiles/{id}
     * @secure
     */
    export namespace DeleteUserProfilesId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteUserProfilesIdData;
    }
}

export namespace UserVerification {
    /**
     * No description
     * @tags User-verification
     * @name GetUserVerifications
     * @request GET:/user-verifications
     * @secure
     */
    export namespace GetUserVerifications {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetUserVerificationsData;
    }

    /**
     * No description
     * @tags User-verification
     * @name PostUserVerifications
     * @request POST:/user-verifications
     * @secure
     */
    export namespace PostUserVerifications {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UserVerificationRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostUserVerificationsData;
    }

    /**
     * No description
     * @tags User-verification
     * @name GetUserVerificationsId
     * @request GET:/user-verifications/{id}
     * @secure
     */
    export namespace GetUserVerificationsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetUserVerificationsIdData;
    }

    /**
     * No description
     * @tags User-verification
     * @name PutUserVerificationsId
     * @request PUT:/user-verifications/{id}
     * @secure
     */
    export namespace PutUserVerificationsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = UserVerificationRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutUserVerificationsIdData;
    }

    /**
     * No description
     * @tags User-verification
     * @name DeleteUserVerificationsId
     * @request DELETE:/user-verifications/{id}
     * @secure
     */
    export namespace DeleteUserVerificationsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteUserVerificationsIdData;
    }
}

export namespace Voting {
    /**
     * No description
     * @tags Voting
     * @name GetVotings
     * @request GET:/votings
     * @secure
     */
    export namespace GetVotings {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetVotingsData;
    }

    /**
     * No description
     * @tags Voting
     * @name PostVotings
     * @request POST:/votings
     * @secure
     */
    export namespace PostVotings {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = VotingRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostVotingsData;
    }

    /**
     * No description
     * @tags Voting
     * @name GetVotingsId
     * @request GET:/votings/{id}
     * @secure
     */
    export namespace GetVotingsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetVotingsIdData;
    }

    /**
     * No description
     * @tags Voting
     * @name PutVotingsId
     * @request PUT:/votings/{id}
     * @secure
     */
    export namespace PutVotingsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = VotingRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutVotingsIdData;
    }

    /**
     * No description
     * @tags Voting
     * @name DeleteVotingsId
     * @request DELETE:/votings/{id}
     * @secure
     */
    export namespace DeleteVotingsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteVotingsIdData;
    }
}

export namespace VotingResult {
    /**
     * No description
     * @tags Voting-result
     * @name GetVotingResults
     * @request GET:/voting-results
     * @secure
     */
    export namespace GetVotingResults {
        export type RequestParams = {};
        export type RequestQuery = {
            /** Sort by attributes ascending (asc) or descending (desc) */
            sort?: string;
            /** Return page/pageSize (default: true) */
            "pagination[withCount]"?: boolean;
            /** Page number (default: 0) */
            "pagination[page]"?: number;
            /** Page size (default: 25) */
            "pagination[pageSize]"?: number;
            /** Offset value (default: 0) */
            "pagination[start]"?: number;
            /** Number of entities to return (default: 25) */
            "pagination[limit]"?: number;
            /** Fields to return (ex: title,author) */
            fields?: string;
            /** Relations to return */
            populate?: string;
            /** Filters to apply */
            filters?: Record<string, any>;
            /** Locale to apply */
            locale?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetVotingResultsData;
    }

    /**
     * No description
     * @tags Voting-result
     * @name PostVotingResults
     * @request POST:/voting-results
     * @secure
     */
    export namespace PostVotingResults {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = VotingResultRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PostVotingResultsData;
    }

    /**
     * No description
     * @tags Voting-result
     * @name GetVotingResultsId
     * @request GET:/voting-results/{id}
     * @secure
     */
    export namespace GetVotingResultsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetVotingResultsIdData;
    }

    /**
     * No description
     * @tags Voting-result
     * @name PutVotingResultsId
     * @request PUT:/voting-results/{id}
     * @secure
     */
    export namespace PutVotingResultsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = VotingResultRequest;
        export type RequestHeaders = {};
        export type ResponseBody = PutVotingResultsIdData;
    }

    /**
     * No description
     * @tags Voting-result
     * @name DeleteVotingResultsId
     * @request DELETE:/voting-results/{id}
     * @secure
     */
    export namespace DeleteVotingResultsId {
        export type RequestParams = {
            id: number;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = DeleteVotingResultsIdData;
    }
}

export namespace UploadFile {
    /**
     * @description Upload files
     * @tags Upload - File
     * @name UploadCreate
     * @request POST:/upload
     * @secure
     */
    export namespace UploadCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UploadCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = UploadCreateData;
    }

    /**
     * @description Upload file information
     * @tags Upload - File
     * @name UploadIdCreate
     * @request POST:/upload?id={id}
     * @secure
     */
    export namespace UploadIdCreate {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {
            /** File id */
            id: string;
        };
        export type RequestBody = UploadIdCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = UploadIdCreateData;
    }

    /**
     * No description
     * @tags Upload - File
     * @name FilesList
     * @request GET:/upload/files
     * @secure
     */
    export namespace FilesList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = FilesListData;
    }

    /**
     * No description
     * @tags Upload - File
     * @name FilesDetail
     * @request GET:/upload/files/{id}
     * @secure
     */
    export namespace FilesDetail {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = FilesDetailData;
    }

    /**
     * No description
     * @tags Upload - File
     * @name FilesDelete
     * @request DELETE:/upload/files/{id}
     * @secure
     */
    export namespace FilesDelete {
        export type RequestParams = {
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = FilesDeleteData;
    }
}

export namespace UsersPermissionsAuth {
    /**
     * @description Redirects to provider login before being redirect to /auth/{provider}/callback
     * @tags Users-Permissions - Auth
     * @name ConnectDetail
     * @summary Login with a provider
     * @request GET:/connect/{provider}
     * @secure
     */
    export namespace ConnectDetail {
        export type RequestParams = {
            /**
             * Provider name
             * @pattern .*
             */
            provider: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }

    /**
     * @description Returns a jwt token and user info
     * @tags Users-Permissions - Auth
     * @name LocalCreate
     * @summary Local login
     * @request POST:/auth/local
     * @secure
     */
    export namespace LocalCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = LocalCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = LocalCreateData;
    }

    /**
     * @description Returns a jwt token and user info
     * @tags Users-Permissions - Auth
     * @name LocalRegisterCreate
     * @summary Register a user
     * @request POST:/auth/local/register
     * @secure
     */
    export namespace LocalRegisterCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = LocalRegisterCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = LocalRegisterCreateData;
    }

    /**
     * No description
     * @tags Users-Permissions - Auth
     * @name CallbackDetail
     * @summary Default Callback from provider auth
     * @request GET:/auth/{provider}/callback
     * @secure
     */
    export namespace CallbackDetail {
        export type RequestParams = {
            /** Provider name */
            provider: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CallbackDetailData;
    }

    /**
     * No description
     * @tags Users-Permissions - Auth
     * @name ForgotPasswordCreate
     * @summary Send rest password email
     * @request POST:/auth/forgot-password
     * @secure
     */
    export namespace ForgotPasswordCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ForgotPasswordCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = ForgotPasswordCreateData;
    }

    /**
     * No description
     * @tags Users-Permissions - Auth
     * @name ResetPasswordCreate
     * @summary Rest user password
     * @request POST:/auth/reset-password
     * @secure
     */
    export namespace ResetPasswordCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ResetPasswordCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = ResetPasswordCreateData;
    }

    /**
     * No description
     * @tags Users-Permissions - Auth
     * @name ChangePasswordCreate
     * @summary Update user's own password
     * @request POST:/auth/change-password
     * @secure
     */
    export namespace ChangePasswordCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = ChangePasswordCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = ChangePasswordCreateData;
    }

    /**
     * No description
     * @tags Users-Permissions - Auth
     * @name EmailConfirmationList
     * @summary Confirm user email
     * @request GET:/auth/email-confirmation
     * @secure
     */
    export namespace EmailConfirmationList {
        export type RequestParams = {};
        export type RequestQuery = {
            /** confirmation token received by email */
            confirmation?: string;
        };
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = any;
    }

    /**
     * No description
     * @tags Users-Permissions - Auth
     * @name SendEmailConfirmationCreate
     * @summary Send confirmation email
     * @request POST:/auth/send-email-confirmation
     * @secure
     */
    export namespace SendEmailConfirmationCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = SendEmailConfirmationCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = SendEmailConfirmationCreateData;
    }
}

export namespace UsersPermissionsUsersRoles {
    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name PermissionsList
     * @summary Get default generated permissions
     * @request GET:/users-permissions/permissions
     * @secure
     */
    export namespace PermissionsList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = PermissionsListData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name RolesList
     * @summary List roles
     * @request GET:/users-permissions/roles
     * @secure
     */
    export namespace RolesList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = RolesListData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name RolesCreate
     * @summary Create a role
     * @request POST:/users-permissions/roles
     * @secure
     */
    export namespace RolesCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = {
            name?: string;
            description?: string;
            type?: string;
            permissions?: UsersPermissionsPermissionsTree;
        };
        export type RequestHeaders = {};
        export type ResponseBody = RolesCreateData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name RolesDetail
     * @summary Get a role
     * @request GET:/users-permissions/roles/{id}
     * @secure
     */
    export namespace RolesDetail {
        export type RequestParams = {
            /** role Id */
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = RolesDetailData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name RolesUpdate
     * @summary Update a role
     * @request PUT:/users-permissions/roles/{role}
     * @secure
     */
    export namespace RolesUpdate {
        export type RequestParams = {
            /** role Id */
            role: string;
        };
        export type RequestQuery = {};
        export type RequestBody = {
            name?: string;
            description?: string;
            type?: string;
            permissions?: UsersPermissionsPermissionsTree;
        };
        export type RequestHeaders = {};
        export type ResponseBody = RolesUpdateData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name RolesDelete
     * @summary Delete a role
     * @request DELETE:/users-permissions/roles/{role}
     * @secure
     */
    export namespace RolesDelete {
        export type RequestParams = {
            /** role Id */
            role: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = RolesDeleteData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name UsersList
     * @summary Get list of users
     * @request GET:/users
     * @secure
     */
    export namespace UsersList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = UsersListData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name UsersCreate
     * @summary Create a user
     * @request POST:/users
     * @secure
     */
    export namespace UsersCreate {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = UsersCreatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = UsersCreateData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name UsersDetail
     * @summary Get a user
     * @request GET:/users/{id}
     * @secure
     */
    export namespace UsersDetail {
        export type RequestParams = {
            /** user Id */
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = UsersDetailData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name UsersUpdate
     * @summary Update a user
     * @request PUT:/users/{id}
     * @secure
     */
    export namespace UsersUpdate {
        export type RequestParams = {
            /** user Id */
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = UsersUpdatePayload;
        export type RequestHeaders = {};
        export type ResponseBody = UsersUpdateData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name UsersDelete
     * @summary Delete a user
     * @request DELETE:/users/{id}
     * @secure
     */
    export namespace UsersDelete {
        export type RequestParams = {
            /** user Id */
            id: string;
        };
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = UsersDeleteData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name GetUsersPermissionsUsersRoles
     * @summary Get authenticated user info
     * @request GET:/users/me
     * @secure
     */
    export namespace GetUsersPermissionsUsersRoles {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = GetUsersPermissionsUsersRolesData;
    }

    /**
     * No description
     * @tags Users-Permissions - Users & Roles
     * @name CountList
     * @summary Get user count
     * @request GET:/users/count
     * @secure
     */
    export namespace CountList {
        export type RequestParams = {};
        export type RequestQuery = {};
        export type RequestBody = never;
        export type RequestHeaders = {};
        export type ResponseBody = CountListData;
    }
}
