import type { FC } from "react";
import { useSearchParams } from "react-router-dom";

import { PopupWindow, PublicLayout } from "src/components";
import { useTranslation } from "src/translations";
import { useComponentDidMount } from "src/utils";
import { SignUpForm } from "./SignInForm";

export const SignInPage: FC = () => {
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const sessionExpired = searchParams.get("sessionExpired");

    useComponentDidMount(() => {
        if (sessionExpired) {
            PopupWindow.fire({
                title: t("signIn.sessionExpired.title"),
                text: t("signIn.sessionExpired.text"),
                icon: "warning",
            });
        }
    });

    return (
        <PublicLayout>
            <SignUpForm />
        </PublicLayout>
    );
};
