import type { FC } from "react";

import { PublicLayout } from "src/components";
import { useEhubDetectSearch } from "src/eHub";
import { SignUpForm } from "./SignUpForm";

export const SignUpPage: FC = () => {
    useEhubDetectSearch();
    return (
        <PublicLayout>
            <SignUpForm />
        </PublicLayout>
    );
};
